.reset-password {
	width: 100%;

	.modal-title {
		font-family: UntitledSans-Regular;
	}

	.form-text {
		text-align: center;
	}

	strong {
		cursor: pointer;

		&:before {
			content: '';
			margin-right: 5px;
			border: solid #000;
			border-width: 0 3px 3px 0;
			display: inline-block;
			padding: 3px;
			transform: rotate(135deg);
		}
	}

	input {
		margin: 10px 0;
	}

	button {
		float: right;
		padding: 9px;
	}
}