.modal.SavePersonalShopping {
	.modal-dialog {
		max-width: 800px;
	}

	.loader {
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, .7);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
		pointer-events: none;
	}

	.close {
		width: 19px;
		height: 19px;
		right: 18px;
		top: 18px;
		position: absolute;
		left: auto;
		&:before, &:after {
			left: 9px;
		}
	}

	.modal-header {
		font-family: UntitledSans-Medium;
		font-size: 16px;
		letter-spacing: 0.51px;
		padding-bottom: 16px;
		border-bottom: 1px solid #f4f4f4;
	}
	.modal-body {
		max-width: 647px;

		textarea, label {
			font-family: UntitledSans-Regular;
			font-size: 14px;
			line-height: 26px;
			letter-spacing: 0.73px;
			text-transform: none;
			color: #000;
		}
		label {
			padding-top: 26px;
		}
		textarea {
			font-size: 16px;
			min-height: 202px;
			background-color: #F4F4F4;
			padding: 8px 19px;
			border: none;
			outline: none;
			box-shadow: none;
			border-radius: 0;
		}
		.error {
			font-family: UntitledSans-Regular;
			font-size: 12px;
			color: #f00;
			text-align: left;
			margin: 0;
			padding-top: 5px;
		}
	}

	.modal-footer {
		padding-top: 42px;
		button {
			height: 50px;
			width: 209px;
			font-size: 16px;
			letter-spacing: 0.5px;
			line-height: 35px;
			text-transform: none;
		}
	}
}