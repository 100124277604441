.search-box {
	font-family: UntitledSans-Regular;
	font-size: 16px;
	letter-spacing: 0.78px;

	.select__control {
		width: 143px;
		border-color: #dbdbdb;

		.select__placeholder {
			white-space: nowrap;
			overflow: hidden;
			width: calc(100% - 25px);
			display: none;
		}

		.select__value-container {
			line-height: 1;
		}

		&.select__control--is-focused {
			width: 255px;
			border-color: #dbdbdb;
			box-shadow: none;

			.select__placeholder {
				display: block;
			}
		}

		.select__indicators {
			.select__indicator-separator {
				display: none;
			}
			.select__dropdown-indicator {
				width: 12px;
				height: 12px;
				right: 16px;
				border-radius: 100%;
				border: 1px solid #000;
				background: #fff;
				display: block;
				position: relative;
				padding: 0;

				svg {
					display: none;
				}

				&:before {
					content: '';
					width: 1px;
					height: 8px;
					position: absolute;
					right: -2px;
					top: 7px;
					display: block;
					background-color: #000;
					transform: rotate(-45deg);
				}
			}
		}
	}

	.select__menu {
		z-index: 999;
		.select__menu-list {
			.select__option {
				&.select__option--is-focused, &:hover{
					background-color: #f4f4f4;
				}
			}
		}
	}

	@media screen and (max-width: 576px) {
		.select__control {
			width: 42px;
		}
	}
}