.nav-bar {
	font-family: UntitledSans;
	height: 70px;
	background: #fff;
	border-bottom: 1px solid #e7e7e7;
	font-size: 16px;

	&.navbar {
		padding: 10px 20px 10px 25px;

	}

	&.retailers-page{
		@media screen and (max-width: 576px) {
			justify-content: flex-start;
		}
	}

	.navbar-nav {
		width: 100%;
		text-align: center;

		&.logged-out {
			flex-direction: row;
		}
		
		span {
			display: flex;
			align-items: center;

			@media screen and (max-width: 576px) {
				align-items: center;
				flex-direction: column;
			}
		}

		a {
			font-family: UntitledSans-Regular;
			display: inline-flex;
			color: #000;
			margin-right: 10px;
			padding: 8px;
			font-size: 16px;
			transition: text-shadow .3s;
			letter-spacing: 1.1px;
			cursor: pointer;

			&.new-messages {
				&:after {
					content: '';
					width: 10px;
					height: 10px;
					position: relative;
					top: 0;
					right: 0;
					display: inline-flex;
					background-color: #e02020;
					border-radius: 50%;
				}
			}

			.inbox {
				width: 24px;
			}

			&.cart-link {
				align-items: center;
				padding: 0 8px;
				&:hover {
					text-shadow: none;
					color: #000;
				}
				.cart-price {
					display: inline-flex;
					align-items: center;
					padding-left: 4px;
					div {
						display: inline-block;
						font-size: 12px;
					}
				}
			}

			&:hover {
				text-shadow: 0 0 1px #000;
				background-color: transparent;
			}

			&.signin {
				font-size: 14px;
			}

			&.gift-card {
				background-color: #000;
				color: #fff;
				padding: 7px 25px;
				margin: 0;
				transition: all .15s ease-in-out;

				&.active, &:hover, &:active, &:focus, &:visited  {
					color: #fff;
					opacity: 0.9;
				}
			}

			&.gift-card {
				font-size: 12px;
				padding: 9px 16px;
				margin-right: 10px;
			}

			&.campaign-button {
				width: 220px;
				height: 46px;
				font-family: UntitledSans-Medium;
				font-size: 14px;
				line-height: 30px;
				background-color: #000;
				color: #fff;
				padding: 7px 15px;
				margin: 0 20px 0 0;
				transition: all .15s ease-in-out;
				text-align: center;
				position: absolute;
				top: 10px;
				right: 0;

				&.active, &:hover, &:active, &:focus, &:visited  {
					color: #fff;
					opacity: 0.9;
				}
			}
			&.campaign-link {
				font-family: UntitledSans-Medium;
				font-size: 16px;
				color: #f88379;
				&:hover {
					text-shadow: 0 0 1px #f88379;
				}
			}
		}

		&.shoppers{
			@media screen and (max-width: 576px) {
				width: fit-content;
			}
		}
		
		.nav-controls {
			text-align: left;
		}

		.user-controls {
			text-align: right;
			@media screen and (max-width: 576px) {
				text-align: left;
			}
		}

		.user-menu {
			button {
				background: #000;
				border: none;
				width: 40px;
				height: 40px;
				border-radius: 20px;
				padding: 0;
				transition: all .2s ease-in-out;

				&:hover, &:focus {
					background-color: #000;
					box-shadow: none;
				}

				&:after {
					display: none;
				}
			}

			.dropdown-menu {
				margin-top: 5px;
				border-radius: 0;
				top: 49px;
				width: 220px;
				border: 1px solid #e7e7e7;

				.dropdown-item, a {
					padding: 10px 20px;

					&:active {
						background-color: #fff;
						color: #000;
						font-weight: 500;
					}
				}
			}
		}

		.social-bar {
			color: #000;
			flex-direction: row;
			font-size: 34px;
			justify-content: space-evenly;
		}
		

		
	}

	.logo {
		height: 48px;
		display: flex;

		@media screen and (min-width: 576px) {
			height: 40px;
			left: 50%;
			transform: translateX(-50%);
			position: absolute;
		}

		img {
			padding: 0 5px;
			height: 100%;
			width: auto;
		}
	}

	.logo-retailers{
		height: 48px;
		display: flex;
		position: relative;
		left:0;
		max-width:100% !important;

		img {
			padding: 0 5px;
			height: 100%;
			width: 88%;
			@media screen and (min-width: 576px) and (max-width: 992px) {
				height: 100%;
				width: 88%;
			}
			@media screen and (max-width: 576px) {
				height: 100%;
				width: 95px/*70%*/;
			}

		}
	}

	button {
		&.navbar-toggler {
			color: #000;
			border: none;
			height: 100%;

			img {
				width: 15px;
			}

			&:focus {
				outline: none;
			}
		}
	}

	.navbar-collapse {
		background-color: #fff;
	}

	.mobile-nav {
		flex-direction: row;
		width: calc(100% - 115px);
		position: absolute;
		left: 70px;
		justify-content: space-between;
		align-items: center;
		padding-right: 5px;
		span {
			flex-direction: row;
		}
	}
	
	

	@media screen and (max-width: 768px) {
		height: 55px;
		font-size: 22px;
		line-height: 1.8;

		.navbar-nav {
			&.logged-out {
				width: auto;
			}
			a {
				margin-right: 10px;
			}
		}
		&.navbar {
			padding: 0;

			.navbar-brand {
				margin: 0 7px;
				max-width: 60px;
			}
			.navbar-toggler {
				padding-right: 20px;
			}
			a {
				display: block;
				margin: 0;
				font-size: 14px;
				&.inbox {
					padding: 8px 15px;
					min-height: 26px;
					min-width: 26px;
					position: relative;
				}
				&.stylistButton{
					font-size: 15px;
					font-weight: 600;
				}
				&.new-messages {
					&:after {
						position: absolute;
						top: 10px;
						right: 13px;
						display: block;

					}
				}
				&.campaign-button {
					font-family: UntitledSans-Medium;
					width: 180px;
					height: 41px;
					justify-content: center;
					margin-right: 10px;
					line-height: 34px;
					letter-spacing: 0;
					padding: 5px;
					top: 6px;
				}
				.cart-icon {
					min-height: 26px;
					min-width: 26px;
				}
			}
		}
	}
}
.nav-demo-button{
	position: relative;
	left: 72.5%;
	font-size: 18px;
	margin: 0 !important;
	
	.btn{
		text-transform: none;
		height:50px;
		width:201px;
		@media screen and (max-width:992px) and (min-width: 700px) {
			left:20%;
			height:44px;
			width:183px;
			font-size: 14px !important;
		}
		@media screen and (max-width: 700px) and (min-width: 576px) {
			left:10%;
			height:44px;
			width:156px;
			font-size: 14px !important;
		}

	}
	@media screen and (max-width:992px) and (min-width: 700px) {
		left:46%;
	}
	
	
}
.shoppers{
	position: relative;
	top:5%;
	left:0%;
	margin:0 !important;
	font-size: 18px;
	@media screen and (max-width: 992px) and (min-width: 576px){
		top:0;
	}
	@media screen and (max-width: 576px){
		padding-left:25px;
	}
}
.shoppers-link{
	padding: 22px 12px 2px 12px !important;

	@media screen and (max-width: 576px){
		padding: 0 !important;
	}
}
