.pagination {
	font-family: UntitledSans-Regular;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 20px;

	svg {
		margin: auto 5px;
		cursor: pointer;

		&.fa-caret-left {
			padding-right: 3px;
		}

		&.fa-caret-right {
			padding-left: 3px;
		}

		&.disabled {
			opacity: .5;
		}
	}

	ul {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
		font-size: 14px;
	}

	li, svg {
		background-color: #fff;
		min-width: 25px;
		min-height: 25px;
		margin: auto 5px;
		color: #000;
		user-select: none;
		cursor: pointer;
		border: 1px solid #000;
		border-radius: 50%;
		text-align: center;

		&.active {
			background-color: #000;
			color: #fff;
		}
	}

	@media screen and (max-width: 756px) {
		font-size: 30px;
		ul {
			font-size: 18px;
		}
		li, svg {
			min-width: 35px;
			min-height: 35px;
			padding: 3px;

			&.fa-caret-left {
				padding-right: 7px;
			}

			&.fa-caret-right {
				padding-left: 7px;
			}
		}
	}
}