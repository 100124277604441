.modal.SaveMoodboard {
	.modal-dialog {
		max-width: 800px;
	}

	.loader {
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, .7);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
		pointer-events: none;
	}

	.close {
		width: 19px;
		height: 19px;
		right: 18px;
		top: 18px;
		position: absolute;
		left: auto;
		&:before, &:after {
			left: 9px;
		}
	}

	.modal-header {
		font-family: UntitledSans-Medium;
		font-size: 16px;
		letter-spacing: 0.51px;
		padding-bottom: 16px;
		border-bottom: 1px solid #f4f4f4;
	}
	.modal-body {
		max-width: 647px;

		textarea, label, button {
			font-family: UntitledSans-Regular;
			font-size: 14px;
			line-height: 26px;
			letter-spacing: 0.73px;
			text-transform: none;
			color: #000;
		}
		label {
			padding-top: 26px;
		}
		textarea {
			min-height: 202px;
			font-size: 16px;
		}
		textarea, button {
			background-color: #F4F4F4;
			padding: 8px 19px;
			border: none;
			outline: none;
			box-shadow: none;
			border-radius: 0;
		}
		button {
			max-width: 237px;
			position: relative;
			justify-content: flex-start;
			&:after {
				width: 7px;
				height: 7px;
				border-bottom: 1px solid #000;
				border-right: 1px solid #000;
				border-top: 0;
				border-left: 0;
				transform: rotate(45deg);
				right: 17px;
				position: absolute;
			}
			&:focus {
				box-shadow: none;
			}
		}
		.form-check {
			margin: 13px 5px 0;
		}
		input[type="checkbox"] {
			display: block;
			opacity: 0;
			position: absolute;
			width: 130px;
			height: 100%;
			z-index: 99;
			margin: 0;
			cursor: pointer;
			&:checked {
				&+label {
					font-family: UntitledSans-Medium;
				}
			}
			&+label {
				position: relative;
				padding: 0;
				font-size: 16px;
				&:before {
					width: 20px;
					height: 20px;
					background-color: #F4F4F4;
					border: none;
					outline: none;
					box-shadow: none;
					border-radius: 0;
				}
				&:after {
					border-bottom: 2px solid #000;
					border-right: 2px solid #000;
					top: 7px;
					left: 8px;
					font-family: UntitledSans-Medium;
				}
			}
		}
		.dropdown-menu {
			max-height: 200px;
			width: 100%;
			max-width: 237px;
			overflow-y: scroll;
		}
		.error {
			font-family: UntitledSans-Regular;
			font-size: 12px;
			color: #f00;
			text-align: left;
			margin: 0;
			padding-top: 5px;
		}
	}

	.modal-footer {
		padding-top: 42px;
		button {
			height: 50px;
			width: 209px;
			font-size: 16px;
			letter-spacing: 0.5px;
			line-height: 35px;
			text-transform: none;
		}
	}
}