.photo-modal {
	width: 100%;
	margin: 0 auto;
	text-align: center;

	.modal-header {
		.close {
			height: 8px;
			right: 8px;
			top: 8px !important;
			left: auto !important;
		}
	}
	.modal-body {
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}