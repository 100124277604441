.profile-photo {
	background-color: #000;
	color: #fff;
	max-width: 100%;
	max-height: 100%;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	text-transform: uppercase;

	&:focus {
		background-color: #fff;
		color: #000;
		transform: scale(1.1);
		box-shadow: none;
	}
	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 18px;
		height: 18px;
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center;
	}
	&.platinum {
		&:after {
			background-image: url(https://media-cf.wishi.me/react/icons/member-platinum.png);
		}
	}
	&.gold {
		&:after {
			background-image: url(https://media-cf.wishi.me/react/icons/member-gold.png);
		}
	}
	&.bronze {
		&:after {
			background-image: url(https://media-cf.wishi.me/react/icons/member-bronze.png);
		}
	}
}