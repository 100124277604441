.masonry {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: stretch;
	width: 100%;
	margin: auto;

	.column{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-content: stretch;
		flex-grow: 1;
	}
	.tile{
		margin: 7px;
		img {
			width: 270px;
		}
	}
}