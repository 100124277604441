@import '../../media.scss';

.inbox {
	&.container-fluid {
		min-height: calc(100vh - 70px);
		background-color: #f4f4f4;
		.container-fluid {
			height: 100%;
		}
	}
	.title {
		font-family: SabonLTStd-Italic;
		font-size: 28px;
		letter-spacing: 1.27px;
		height: 105px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #fff;
		border-bottom: 1px solid #f4f4f4;
	}

	.profile-image-placeholder {
		width: 50px;
		height: 50px;
		background-color: #000;
		border-radius: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: UntitledSans-Medium;
		letter-spacing: 1.1px;
		text-transform: uppercase;
		line-height: 20px;
		color: #fff;
		font-size: 20px;
	}

	@media screen and (max-width: 576px) {
		&.container-fluid {
			height: 100%;
			min-height: auto;
			background-color: #fff;
		}
	}
}

.bookings {
	background-color: #fff;
	.row {
		.col {
			p.title {
				height: 98px;
				font-family: UntitledSans-Medium;
				font-size: 16px;
				letter-spacing: 0.91px;
				justify-content: flex-start;
				padding: 25px;
				margin: 0;
				text-transform: capitalize;
			}

			&.list, &.chat, &.info {
				padding: 0;
				background-color: #fff;
				height: calc(100vh - 70px);
				overflow: hidden;
			}
			&.list {
				max-width: 430px !important;
				border-right: 1px solid #f4f4f4;
				margin: 0;
				.infinite-scroll-component__outerdiv {
					height: 100%;
					.infinite-scroll-component {
						height: auto;
						overflow-y: scroll;
					}
				}
				.back{
					margin-left: 25px;
					margin-top: 15px;
					width: 88%;
					border-bottom: 1px solid #DBDBDB;
					.back-btn {
						position: relative;
						width: fit-content;
						top: 0;
						label{
							font-size: 22px;
							font-weight: 500;
							font-family: UntitledSans-Medium;
							margin-left: 10px;
							margin-bottom: 0px;
							line-height: 27px;
							text-transform: capitalize;
						}
						&:after {
							content: '';
						}
						&:before{
							margin-bottom: 3px;
						}
					}
					p{
						font-size: 16px;
						color: #5A5A61;
						font-weight: 400;
						padding-left: 22px;
						padding-top: 8px;
						margin-bottom: 20px;
					}
				}
				.bookings-list {
					height: calc(100% - 95px) !important;
					overflow: scroll;
					&.archived-list{
						height: calc(100% - 163px) !important;
					}
					&::-webkit-scrollbar {
						display: none;
					}
					scrollbar-width: none;
					.row {
						height: auto;
						border-bottom: 1px solid #DBDBDB !important;
						margin-left: 10px;
						margin-right: 10px;
					}
					.no-results{
						width: 360px;
						font-size: 18px;
						margin-top: 40px;
						line-height: 22px;
						color: #5A5A61;
						align-items: center;
						text-align: center;
					}
				}
			}
			&.chat {
				.chat-header {
					padding: 25px 30px 24px;
					display: flex;
					align-items: center;
					position: relative;
					font-family: UntitledSans;
					border-bottom: 1px solid #f4f4f4;

					.profile-photo {
						width: 42px !important;
						height: 42px !important;
						border-radius: 50% !important;
						font-size: 14px;
						padding: 4px 4px 0px 4px;
						margin-left: 25px;
					}
					.client-info {
						display: flex;
						align-items: center;
						flex-direction: row;
						justify-content: center;
						font-family: UntitledSans;
						.client-name {
							font-size: 16px;
							height: 23px;
							letter-spacing: 0.91px;
							padding-left: 10px;
							margin-right: 10px;
							font-weight: 500;
							margin-bottom: 0rem;
							margin-top: 6px;
							text-transform: capitalize;
						}
						.loyalty{
							width: 22px;
							height: 22px;
							margin-right: 10px;
							margin-top: 4px;
						}
						.client-plan {
							height: 22px;
							background: black;
							color: white;
							font-size: 13px;
							padding: 3px 8px 0px 8px ;
							font-weight: 400;
							margin-top: 2px;
						}
						.social-icons {
							display: flex;
							position: relative;
							left: 15px;
							top: 0;
							a {
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: #000;
								width: 18px;
								height: 18px;
								border-radius: 10px;
								font-size: 12px;
								color: #f4f4f4;
								margin-right: 4px;
							}
						}
					}
					span {
						position: absolute;
						right: 30px;
						display: flex;
						align-items: center;
						.archive {
							background-image: var(--archive);
							background-repeat: no-repeat;
							width: 25px;
							height: 25px;
							margin-top: 4px;
							background-size: 100%;
							cursor: pointer;
							&.disabled {
								pointer-events: none;
								opacity: .3;
							}
							&:hover{
								background-image: var(--archive-hover);	
							}
						}
						.unarchive {
							font-family: UntitledSans-Regular;
							font-size: 14px;
							letter-spacing: 0.45px;
							height: 37px;
							padding: 0 11px;
							text-transform: none;
						}
						.details-toggle {
							font-family: UntitledSans-Medium;
							font-size: 16px;
							letter-spacing: 0.91px;
							padding: 0;
							cursor: pointer;
							margin: 0 0 0 85px;
							&:hover {
								text-decoration: underline;
							}
						}
					}
					.unarchive-comment {
						font-family: UntitledSans-Regular;
						font-size: 16px;
						text-align: center;
						line-height: 25px;
						letter-spacing: 0.35px;
						position: absolute;
						top: 150px;
						left: -150px;
						width: 326px;
						img {
							position: absolute;
							top: -70px;
							left: 180px;
						}
					}
					.more-dropdown {
						&.show {
							.more-btn {
								background-color: #F46C66;
								border: none;
								.more-dots {
									background-image: var(--view-more-red) !important;
									background-size: 80% !important;
									width: 7px !important;
									height: 30px !important;
									background-repeat: no-repeat;
								}
							}
						}
						.more-btn {
							padding: 0;
							width: 20px !important;
							height: auto !important;
							background-color: #fff;
							display: flex;
							justify-content: center;
							border-radius: 4px !important;
							align-items: center;
							border: none;
							box-shadow: none;
							margin-right: 15px;
							margin-top: 2px;
							&:after {
								display: none;
							}
							.more-dots {
								background-image: var(--view-more) !important;
								background-size: 80% !important;
								width: 7px !important;
								height: 30px !important;
								margin: 3px;
								background-repeat: no-repeat;
							}
						}
						.dropdown-menu {
							transform: translate3d(-210px, 30px, 0px) !important;
							overflow: scroll;
							padding: 0;
							border-radius: 0;
							top: 10px !important;
							div {
								font-family: UntitledSans-Medium;
								font-size: 20px;
								color: #4a4a4a;
								padding: 0 13px;
							}
							a {
								font-family: UntitledSans-Regular;
								font-size: 16px;
								line-height: 40px;
								letter-spacing: 0.93px;
								text-transform: capitalize;
								color: #000;
								padding: 0 13px;
								&:active {
									background-color: #fff;
									color: #000;
								}
							}
						}
					}
				}
				.chat-container {
					height: calc(100vh - 150px);
					.client-wrapper {
						display: none;
					}

					.wishi-chat {
						height: 100%;
						max-height: none;
						width: auto;
						border: none;
					}

					.stylist-chat-header {
						display: none;
					}
				}
			}
			&.info {
				max-width: 22%;
				border-left: 1px solid #f4f4f4;
				overflow: scroll;
				transition: max-width 200ms ease-in-out;

				.close {
					top: 32px;
					right: 21px;
					left: auto;
				}

				&.hide {
					max-width: 0;
				}
			}
		}
	}
	&.container-fluid{
		padding-top: 1px !important;
	}
}

.booking {
	cursor: default;
	&.row {
		&.selected {
			.col {
				cursor: default;
				background-color: #f4f4f4;
			}
			.discription{
				cursor: default;
			}
		}
	}

	.booking-content {
		cursor: default !important;
		min-height: 160px;
		width: 360px;
		margin: 0px 0px !important;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: flex-start;
		border-radius: 3px;
		z-index: 1;
		padding-left: 14px !important;
		&.inquiries{
			margin-bottom: 0px !important;
		}

		.important {
			background-image: var(--flag) !important;
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center;
			width: 15px !important;
			position: unset !important;
			cursor: pointer;
			&.off { background-image: var(--flag) !important; }
			&.on { background-image: var(--flag-fill) !important; }
		}

		.view-look-progress{
			width: 360px;
			display: flex;
			flex-direction: column-reverse;
			transition: height 0.5s;
			cursor: default;
			img{
				width: 10px;
				height: 15px;
				position: relative;
				left: 47.5%;
				transform: rotate(270deg);
				cursor: pointer;
				&.open {
					transform: rotate(90deg);
					margin-top: 10px;	
					margin-bottom: 5px;
				}
			}
			p{
				font-size: 16px;
				color: #5A5A61;
				font-weight: 400;
			}
		}

		svg {
			color: #f88379;
			font-size: 20px;
		}

		.booking-title{
			width: 350px;
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			margin-top: 4px;
			cursor: default;
			.new-messages{
				width: 8px;
				height: 8px;
				background: var(--coral);
				border-radius: 50%;
				position: absolute;
				top: 23px;
				left: -10px;
			}
			.client-info{
				display: flex;
				max-width: 230px;
			}
			.session-info{
				display: flex;
				justify-content: center;
    			align-items: baseline;
			}
		}

		.discription{
			display: flex;
			flex-direction: column;
			margin-bottom: 10px;
			width: 350px;
			cursor: pointer;
			.plan-info{
				align-items: baseline;
				flex-direction: row;
				display: flex;
				justify-content: space-between;
				margin-top: 5px;
			}
			.book-plan{
				padding: 4px 8px 4px 8px;
				font-size: 11px;
				font-weight: 500;
				color: white;
				margin-top: 8px;
				margin-bottom: 14px;
				line-height: 10px;
			}
			.book-status{
				font-size: 14px;
				width: fit-content;
				display: flex;
				flex-wrap: wrap;
				p{
					margin-right: 10px;
					margin-bottom: 10px;
					padding: 4px 8px 4px 8px;
					border-radius: 4px;
					width: max-content;
				}
			}
		}

		img{
			&.loyalty {
				width: 20px;
				height: 20px;
				margin-left: 8px;
				margin-top: -2px;
			}
		}

		p {
			margin: 0;
			&.name {
				font-family: UntitledSans-Medium;
				font-size: 18px !important;
				letter-spacing: 0.73px;
				line-height: 1;
				text-transform: capitalize;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				
			}
			&.content {
				font-family: UntitledSans-Regular;
				font-size: 16px !important;
				letter-spacing: 0.64px;
				line-height: 20px;
				width: 290px;
				color: #5A5A61 !important;
				margin-bottom: 14px !important;
				white-space: break-spaces;
				&.bold{
					font-weight: bold;
					color: black !important;
				}
			}
			&.date {
				font-family: UntitledSans-Regular;
				font-size: 14px !important;
				letter-spacing: 0.26px;
				color: #5A5A61 !important;
				text-align: right;
				line-height: 1;
				position: unset !important;
				padding-left: 8px;
			}
			&.followup {
				font-family: UntitledSans-Regular;
				font-size: 14px;
				color: #f88379;
				letter-spacing: 0.44px;
				padding-bottom: 10px;
				display: flex;
				align-items: flex-start;
				width: 384px;
				button {
					font-family: UntitledSans-Medium;
					font-size: 12px;
					letter-spacing: 0.38px;
					color: #000;
					text-decoration: underline;
					cursor: pointer;
					position: absolute;
					right: 10px;
					top: 12px;
					z-index: 10;
					padding: 0;
				}
			}
		}
		.buttons {
			margin-bottom: 0px !important;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			width: 350px;
			&.inquiries{
				margin-bottom: 0px !important;
			}
			button {
				height: 40px;
				font-family: UntitledSans-Regular;
				margin: 0px 0px 10px 0px;
				font-size: 14px;
				letter-spacing: 0.3px;
				text-transform: none;
				line-height: 1;
				&.single-btn{
					width: 350px;
					margin: 0px 0px 10px 0px;
				}
				&.left-btn{
					width: 168px;
					margin: 0px 14px 10px 0px;
				}
				&.right-btn{
					width: 168px;
					margin: 0px;
				}
				&:disabled {
					background-color: #7B7B81;
					color: #fff;
				}
			}
		}
	}




	.booking-archive {
		height: 100px;
		width: 360px;
		margin-top: 10px;
		margin-bottom: 10px;
		cursor: pointer;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: flex-start;
		border-radius: 3px;
		z-index: 1;

		svg {
			color: #f88379;
			font-size: 20px;
		}

		.booking-title{
			width: 350px;
			display: flex;
			justify-content: space-between;
			align-items: baseline;
		}

		span{
			display: flex;
			flex-direction: row;
			align-items: baseline;
		}

		p {
			margin: 0;
			&.looks{
				font-size: 16px;
				font-weight: 400;
			}
			&.name {
				font-family: UntitledSans-Medium;
				font-size: 18px !important;
				letter-spacing: 0.73px;
				line-height: 1;
				text-transform: capitalize;
				padding-right: 3px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				max-width: 185px;
			}
			&.content {
				font-family: UntitledSans-Regular;
				font-size: 16px !important;
				letter-spacing: 0.64px;
				line-height: 20px;
				text-overflow: ellipsis;
				word-wrap: normal;
				overflow: hidden;
				width: 266px;
				color: #5A5A61 !important;
			}
			&.date {
				font-family: UntitledSans-Regular;
				font-size: 14px !important;
				letter-spacing: 0.26px;
				color: #5A5A61 !important;
				text-align: right;
				line-height: 1;
				position: unset !important;
				padding-left: 7px;
			}
		}
	}
}


.btn-light:not(:disabled):not(.disabled).active, 
.btn-light:not(:disabled):not(.disabled):active, 
.show>.btn-light.dropdown-toggle{
	background-color: white !important;
	.filter-icon{
		background-image: var(--filters-red) !important;
		.filter-selected{
			width: 10px !important;
			height: 10px !important;
			margin-top: -2px !important;
			margin-right: 2px !important;
		}
	}
	.side-menu{
		background-image: var(--view-more-red) !important;
		&:hover{
			background-image: var(--view-more-red) !important;
		}
	}
}

.filters{
	max-width: 340px !important;
}

.quick-filters{
	&.row{
		margin-right: -8px !important;
		align-items: center;
	}
}

.bookings-filter {
	&.container {
		max-width: 385px !important;
		margin-top: 16px !important;
		margin-bottom: 16px !important;
		padding: 0 !important;
	}
	.row {
		margin: 0;
		.col {
			padding: 0;
			.new-messages{
				display: flex;
				align-items: center;
				margin-bottom: 4px;
				height: 40px;
				width: 45px;
				margin-left: 5px;
				&.clicked{
					border-radius: 4px;
					margin-top: 2px;
					.inbox{
						background-image: var(--new-messages-red) !important;
					}
					.messages{
						color: white;
					}
				}
				.inbox{
					background-image: var(--new-messages);
					height: 30px;
					width: 30px;
					margin: 10px;
					background-size: 85%;
					background-repeat: no-repeat;
					background-position: center;
					&:hover{
						cursor: pointer;
						background-image: var(--new-messages-hover);
					}
				}
				.messages{
					color: white;
					background-color: var(--coral);
					border-radius: 50%;
					position: absolute;
					text-align: center;
					top: 2px;
					right: 6px;
					height: 17px;
					min-width: 16px;
					font-size: 10px;
					font-weight: 600;
					border: 1.5px solid white;
					padding: 0px !important;
					z-index: 10;
					&.medium{
						right: 0px;
						border-radius: 10px;
						p{
							padding-left: 4px;
							padding-right: 4px;
						}
					}
					&.large{
						right: -2px;
						border-radius: 10px;
						p{
							padding-left: 3px;
							padding-right: 3px;
						}
					}
				}
			}
			.more-dropdown {
				&.show {
					.more-btn {
						background-color: var(--coral);
						border: none;
						border-radius: 4px;
					}
				}
				.more-btn {
					background-color: #fff;
					display: flex;
					align-items: center;
					border: none;
					box-shadow: none;
					justify-content: center;
					padding-bottom: 3px !important;
					padding-top: 8px !important;
					.side-menu {
						background-image: var(--view-more);
						background-size: 55%;
						width: 10px;
						height: 30px;
						background-repeat: no-repeat;
					}
					&:after {
						display: none;
					}
					&:hover{
						.side-menu {
							background-image: var(--view-more-hover);
						}
					}
				}
				.dropdown-menu {
					transform: translate3d(-100px, 35px, 0px) !important;
					padding: 0;
					border-radius: 0;
					&.show{
						top: 13px !important;
						border-radius: 4px !important;
						min-width: 8rem !important;
					}
					div {
						font-family: UntitledSans-Medium;
						font-size: 20px;
						color: #4a4a4a;
						padding: 0 13px;
					}
					a {
						font-family: UntitledSans-Regular;
						font-size: 16px;
						line-height: 40px;
						letter-spacing: 0.93px;
						text-transform: capitalize;
						color: #000;
						padding: 0 13px;
						&:active {
							background-color: #fff;
							color: #000;
						}
					}
				}
			}
			&.filters {
				max-width: 45px;
				input[type="checkbox"]:checked + label {
					&.single {
						&:before {
							background: #fff;
						}
						&:after {
							display: none;
						}
					}
				}
				input[type=checkbox]:checked + label.single:before{
					background: var(--coral);
					border: 1px solid black !important;
				}
				input[type=checkbox]:checked + label:before{
					background: var(--coral);
					border: none !important;
				}
				input[type=checkbox]:checked + label:after{
					top: 8px !important;
					left: 7px !important;
				}
				.filter-item{
					.filter-label{
						margin-bottom: 7px;
						&:before{
							position: relative !important;
							border: 1px solid #9A9A9A;
							min-width: 20px !important;
							min-height: 20px !important;
							margin-right: 8px;
							bottom: 3px;
						}
						&:after{
							border-bottom: 2px solid #fff !important;
							border-right: 2px solid #fff !important;
						}
					}
				}
				.dropdown {
					&.show {
						.dropdown-toggle:focus {
							box-shadow: none;
							border-color: #dbdbdb;
						}
					}


					.filters-list .filter-row {
						border-bottom: 1px solid #DBDBDB !important;
						padding: 0;
						.card-body {
							margin-bottom: 5px;
							overflow: hidden !important;
							max-height: 350px;
						}
						.filter {
							font-size: 16px;
							font-weight: bolder;
							font-family: UntitledSans-Regular;
							line-height: 41px;
							letter-spacing: 0.82px;
							padding-top: 10px;
							padding-bottom: 5px;
							.expand-sign {
								&:after {
									content: '';
									background-image: var(--arrow-left);;
									width: 15px;
									height: 15px;
									top: 20px;
									background-size: 50%;
									background-repeat: no-repeat;
									transform: rotate(270deg);
									transition: transform 300ms ease-in-out;
								}
							}
						}
						&.active {
							.filter {
								.expand-sign {
									&:after {
										content: '';
										top: 25px;
										transform: rotate(90deg);
									}
								}
							}
						}
					}

					.filter-dropdown {
						padding: 0;
						width: 43px;
						height: 43px;
						border: none;
						display: flex;
						justify-content: center;
						align-items: center;
						z-index: 10;
						margin-left: -44px;
						margin-top: 1px;

						.filter-icon {
							width: 100%;
							height: 100%;
							background-image: var(--filters);
							cursor: pointer;
							background-repeat: no-repeat;
							background-size: 74%;
							margin-right: 4px;
							margin-top: 17px;
							margin-left: 9px;
							display: flex;
							flex-direction: row-reverse;
							.filter-selected{
								width: 10px;
								height: 10px;
								background-color: var(--coral);
								border-radius: 50%;
								position: relative;
								margin-top: -2px;
								margin-right: 2px;
								border: 1.5px solid white;
							}
						}
						&:hover{
							.filter-icon{
								background-image: var(--filters-hover);
								.filter-selected{
									width: 10px;
									height: 10px;
									margin-top: -2px;
									margin-right: 2px;
								}
							}
						}
						&:after {
							display: none;
						}
					}

					.dropdown-menu {
						transform: translate3d(0px, 50px, 0px) !important;
						min-width: 430px;
						max-height: 80%;
						overflow: scroll;
						padding: 0 0 !important;
						position: fixed !important;
						will-change: auto;
						background-clip: inherit;
						top: 98px !important;
						left: 0px !important;
						border-radius: 0px;
						box-shadow: -1px 6px 8px 0px rgb(3 15 58 / 20%);
						.menu-header{
							display: flex;
							flex-direction: row;
							position: sticky;
							top: 0;
							z-index: 10;
							padding: 20px 30px 10px 25px;
							background: white;
							justify-content: space-between;
							align-items: baseline;
							box-shadow: 0px 2px 10px rgba(3, 15, 58, 0.07);
							label{
								font-family: UntitledSans-Medium;
								border: none;
								height: fit-content;
								&.title{
									font-size: 18px;
									font-weight: 500;
								}
								&.reset{
									font-size: 16px;
									&:hover{
										cursor: pointer;
									}
								}
							}
						}
						.sticky-btn{
							position: sticky;
							right: 0;
							bottom: 0;
							left: 0;
							width: 360px;
							height: 45px;
							margin-left: 35px;
							margin-top: 10px;
							&.btn-warning{
								padding: 12px 5px !important;
								text-transform: capitalize;
								font-family: 'UntitledSans-Regular';
							}
							&:hover{
								cursor: pointer;
								opacity: 1;
							}
						}
						div {
							font-family: UntitledSans-Medium;
							font-size: 20px;
							padding: 0px;
						}
						a {
							font-family: UntitledSans-Regular;
							font-size: 16px;
							line-height: 47px;
							letter-spacing: 0.34px;
							text-transform: capitalize;
							color: #000;
							padding: 0 13px;
							&:active {
								background-color: #fff;
								color: #000;
							}
						}
						.more {
							font-family: UntitledSans-Medium;
							font-size: 16px;
							letter-spacing: 0.73px;
							cursor: pointer;
							display: flex;
							align-items: center;
							&.off {
								i {
									transform: rotate(180deg);
								}
							}
							i {
								position: relative;
								width: 0;
								height: 0;
								border-left: 5px solid transparent;
								border-right: 5px solid transparent;
								border-bottom: 5px solid #000;
								left: 10px;
							}
						}
					}
				}
			}
			.search-box {
				&.search-on {
					.select__control {
						.select__value-container {
							padding-left: 8px;
						}
						.select__dropdown-indicator {
							display: none;
						}
						.select__indicators {
							left: auto;
							right: 0;
						}
					}
				}

				.select__control {
					height: 40px;
					width: 280px;
					font-size: 14px;
					border-radius: 4px !important;
					position: relative;
					.select__placeholder {
						display: block;
						width: calc(100% - 40px) !important;
					}
					.select__value-container {
						padding-left: 40px;
					}
					.select__clear-indicator {
						position: relative;
						color: #000001ba;
					}
					.select__indicators {
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 40px;
						cursor: pointer;
						.select__dropdown-indicator {
							border: none !important;
							background-image: var(--search);
							left: 17px !important;
							width: 17px;
							height: 20px;
							background-repeat: no-repeat;
							background-size: 100%;
							border-radius: 0% !important;
							&:before {
								display: none !important;
								background-color: none !important;
							}
						}
					}
				}
			}
		}
	}
}

.messages {
	background-color: #f4f4f4;
	padding-bottom: 26px;
	.container {
		max-width: 92%;
	}

	@media screen and (max-width: 576px) {
		padding-bottom: 0;
		background-color: #fff;
		.container {
			max-width: 100%;
		}
	}
}

.message {
	height: 134px;
	background-color: #fff;
	margin-top: 26px;
	padding: 0 18px;
	cursor: pointer;

	.col {
		display: flex;
		justify-content: space-between;
	}

	.stylist-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		max-width: 120px;
		margin: 0;

		img {
			width: 80px;
			height: 80px;
			border-radius: 40px;
		}
		p {
			font-family: UntitledSans-Regular;
			font-size: 12px;
			letter-spacing: 0.25px;
			color: #fff;
			text-transform: uppercase;
			background-color: #f88379;
			text-align: center;
			height: 19px;
			line-height: 1.6;
			padding: 0 12px;
			&.cleanout {
				background-color: #74ae37;
			}
		}
	}
	.text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		svg {
			color: #f88379;
			font-size: 20px;
		}
		p {
			margin: 0;
			&.name {
				font-family: UntitledSans-Medium;
				font-size: 18px;
				letter-spacing: 0.82px;
			}
			&.content {
				font-family: UntitledSans-Light;
				font-size: 16px;
				letter-spacing: 0.73px;
				text-overflow: ellipsis;
				word-wrap: normal;
				overflow: hidden;
				max-height: 3.6em;
				line-height: 1.7em;
				padding-right: 65px;
				max-width: 856px;
			}
			&.date {
				font-family: UntitledSans-Light;
				font-size: 12px;
				letter-spacing: 0.55px;
				color: #4a4a4a;
			}
		}
	}
	.buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		button {
			min-width: 175px;
			padding: 15px;
			margin-left: 10px;
			white-space: nowrap;
			&.btn-light {
				&:hover {
					background-color: #000;
					color: #fff;
				}
			}
		}
	}

	@media screen and (max-width: 576px) {
		margin: 1px;
		height: 150px;
		border-bottom: 1px solid #f4f4f4;
		padding: 20px 0px !important;
		.col {
			flex-direction: column;
			justify-content: space-around;
			padding: 0;
		}
		.stylist {
			max-width: 67px;
			margin-right: 10px;
			img {
				width: 67px;
				height: 67px;
			}
		}
		.text {
			width: 200px !important;
			p {
				&.content {
					font-size: 14px;
					padding: 0;
					height: 50px;
					max-width: 523px;
					width: 72%;
				}
				&.date {
					position: absolute;
					top: 5px;
					right: 10px;
					&:after {
						position: relative;
						top: -2px;
						left: 2px;
						content: '';
						display: inline-block;
						width: 5px;
						height: 5px;
						border-right: 1px solid black;
						border-top: 1px solid black;
						transform: rotate(45deg);
					}
				}
			}
		}
		.buttons {
			max-width: 100%;
			display: block;
			margin-top: 5px;
			button {
				min-width: 0;
				max-height: 25px;
				font-size: 12px;
				line-height: .1;
				border: none;
				background-color: #f88379;
				color: #fff;
				padding: 15px 20px;
				margin-left: 0;
				margin-right: 10px;
			}
		}
	}
}

.client-info {
	.nav-tabs {
		border: none;
		justify-content: space-evenly;
		.nav-item {
			font-family: UntitledSans-Regular;
			font-size: 12px;
			letter-spacing: 0.26px;
			color: #000;
			border: none;
			padding: 0;
			margin: 17px 0;
			&.active {
				font-family: UntitledSans-Medium;
				color: #000;
				border-bottom: 2px solid;
			}
		}
	}
	.profile-image-placeholder {
		margin: 0 auto;
	}

	.tab-content {
		width: 100%;
		margin: 0 auto;

		.tab-pane {
			.photos {
				.photos-carousel {
					margin-bottom: 18px;
					.slick-slide {
						img {
							height: 375px;
							object-fit: contain;
							cursor: pointer;
						}
					}
					.slick-next,
					.slick-prev {
						width: 34px;
						height: 34px;
						border-radius: 17px;
						background-color: #fff;
						border: 1px solid #979797;
						z-index: 99;
						&:before {
							position: relative;
							content: '';
							display: inline-block;
							width: 10px;
							height: 10px;
							border-right: 2px solid black;
							border-top: 2px solid black;

						}
					}
					.slick-prev {
						left: -10px;
						&:before {
							left: 2px;
							transform: rotate(225deg);
						}
					}
					.slick-next {
						right: -10px;
						&:before {
							right: 2px;
							transform: rotate(45deg);
						}
					}
				}
				.list-group {
					.list-group-item {
						border: none;
						padding: 0;
						text-transform: capitalize;
						font-family: UntitledSans-Regular;
						font-size: 12px;
						letter-spacing: 0.26px;
						margin-bottom: 15px;
						.blank-image {
							min-width: 24px;
							min-height: 24px;
							border: 2px solid #f4f4f4;
							margin-right: 16px;
							display: inline-block;
						}
						.col-1 {
							img {
								min-width: 15px;
								max-width: 15px;
								max-height: 15px;
								margin-right: 16px;
								display: inline-block;
							}
						}

						b {
							font-family: UntitledSans-Medium;
							font-weight: normal;
						}
						.row {
							align-items: center;
							.col {
								img {
									width: 36px;
									height: 13px;
									margin-right: 4px;
								}
							}
						}
					}
				}
			}
			.style {
				font-family: UntitledSans-Regular;
				.col-12 {
					p {
						font-size: 16px;
						letter-spacing: 0.5px;
						text-align: center;
						margin: 0;
						padding-top: 30px;
					}
				}
				.moodboard {
					text-align: center;
					p {
						font-size: 14px;
						color: #4a4a4a;
						margin: 10px auto 6px;
						text-align: center;
						text-transform: capitalize;
					}
					img {
						max-width: 70px;
					}
				}
			}
			.boards {
				.toggle-button {
					height: 30px;
					width: 100%;
					button {
						padding: 8px 0;
						letter-spacing: 0.56px;
						line-height: 1;
						text-transform: capitalize;
						border-color: #000;
						border-radius: 0;
					}
				}
				.col {
					text-align: center;
					img {
						max-width: 275px;
						border: 1px solid #f4f4f4;
						margin-bottom: 13px;
					}
				}
			}
			.notes {
				.note {
					border-bottom: 1px solid #f4f4f4;
					padding: 20px 0;
					font-family: UntitledSans-Regular;
					.note-header {
						height: auto;
						padding-bottom: 20px;
						p {
							font-size: 12px;
							line-height: 23px;
							letter-spacing: 0.64px;
							margin: 0;
							&.name {
								font-family: UntitledSans-Medium;
							}
							&.time {
								color: #9b9b9b;
							}
						}
						.profile-photo {
							width: 36px;
							height: 36px;
						}
					}
					.note-content {
						font-size: 16px;
						line-height: 19px;
						height: auto;
					}
				}
			}
		}
	}
}

.book-status-grid{
	display: grid;
    grid-template-columns: auto auto auto;
	margin-left: 4px;
	padding-bottom: 16px;
	width: 395px;

	.book-status{
		height: 70px;
		width: 120px;
		border-radius: 7px;
		border: 1.5px solid #DBDBDB;
		margin: 4px;
		font-family: UntitledSans-Regular;
		align-items: center;
		text-align: center;
		&:hover{
			cursor: pointer;	
		}
	
		&.selected {
			border: 1.5px solid black;
			background: #F4F4F4;
		}
	
		.book-status-count{
			font-size: 20px;
			font-weight: bolder;
			margin: auto;
			margin-top: 8%;
		}
		
		.book-status-name{
			font-size: 14px;
		}
	
	}
}

.jump-to-top-btn{
	position: fixed;
	bottom: 2.5%;
	left: 385px;
	z-index: 1;
	color: black;
	padding: 9px;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	border: none;
	box-shadow: 0px 1.86957px 9.34783px rgba(0, 0, 0, 0.25);
	cursor: pointer;
	.jump-icon{
		width: 40px;
		height: 40px;
		background-image: var(--back-to-top);
		background-size: 95%;
		background-repeat: no-repeat;
		margin-top: 3px;
		margin-left: 2px;
	}
	&:hover{
		border: none;
		opacity: 1;
		box-shadow: 0px 1.86957px 9.34783px rgb(0 0 0 / 82%);
	}
}