.profile-cart,
.profile-closet,
.profile-collections,
.profile-looks,
.profile-photos,
.profile-orders {
	height: 100%;
	>.row:first-of-type {
		height: 100%;
		width: 100%;
		position: absolute;
	}
	.filters-column {
		max-width: 200px;
		height: calc(100vh - 170px);
		overflow: scroll;
		overflow-x: auto;
		overflow-y: auto;
		border-right: 3px solid #f4f4f4;
		padding-left: 40px;
		.total {
			padding-top: 25px;
			font-family: UntitledSans-Medium;
			font-size: 14px;
			letter-spacing: 0.82px;
		}
		.filters {
			&.categories-all {
				.filter-row.category_uuid {
					.filter .selection {
						&:after {
							content: 'All categories';
						}
					}
				}
			}
			.filters-list .filter-row {
				border-bottom: 0 !important;
				padding: 0;
				.filter {
					font-size: 14px;
					font-family: UntitledSans-Regular;
					line-height: 41px;
					letter-spacing: 0.82px;
					.expand-sign {
						&:after {
							content: '';
							width: 0;
							height: 0;
							top: 20px;
							border-left: 5px solid transparent;
							border-right: 5px solid transparent;
							border-top: 5px solid #2f2f2f;
							transition: transform 300ms ease-in-out;
						}
					}
				}
				&.active {
					.filter {
						.expand-sign {
							&:after {
								content: '';
								transform: rotate(180deg);
							}
						}
					}
				}
				.filter-search {
					.filter-label {
						padding: 3px 0;
						display: block;
						text-align: left;
					}
				}
				.filter-item {
					&.selected {
						.filter-label {
							font-family: UntitledSans-Medium;
						}
					}
					.filter-label {
						font-size: 14px;
						&:before {
							position: relative;
							margin-right: 8px;
							min-width: 11px;
							max-width: 11px;
							max-height: 11px;
							border: 1px solid #000;
						}
						&:not(.single) {
							&:before {
								border-radius: 0;
							}
						}
					}
				}
			}
			.show-all {
				font-size: 14px;
			}
		}
	}
	.results {
		height: calc(100vh - 140px);
		overflow: auto;
		padding: 25px 40px;
		.results-bar {
			display: flex;
			align-items: center;
			padding: 9px 5px 12px;
			position: relative;
			min-height: 50px;
			.dropdown {
				.dropdown-toggle, .dropdown-toggle:focus{
					font-family: UntitledSans-Regular;
					font-size: 14px;
					border: 1px solid #1D1D1D;
					height: 32px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 13px;
					border-radius: 2px;
					&:after {
						top: 0;
						border-right: 1px solid #1D1D1D;
						border-top: 1px solid #1D1D1D;
						width: 6px;
						height: 6px;
					}
				}
				.dropdown-item {
					font-size: 14px;
					padding: 5px 15px;
				}
			}
			.filter-badges {
				max-width: calc(100% - 270px);
				.badge {
					position: relative;
					margin: 5px 13px 5px 0;
					font-size: 14px;
					border-color: #1D1D1D;
					height: 32px;
					padding: 8px 15px;

					.close {
						top: 3px;
						right: 6px;
						left: auto;
						width: 7px;
						height: 7px;
						&:before, &:after {
							height: 7px;
							left: auto;
							right: 0;
							width: 1px;
						}
					}
				}
			}
			.search-box {
				padding-right: 13px;
				display: inline-flex;
				&.expanded {
					.select__control {
						width: 150px;
						.select__input {
							color: #000;
						}
					}
				}
				.select__indicators {
					display: block;
				}
				.select__control {
					width: 32px;
					min-height: 32px;
					height: 32px;
					border-color: #1D1D1D;
					border-radius: 2px;
					overflow: hidden;
					&.select__control--is-focused {
						.select__value-container {
							padding-left: 8px;
						}
						.select__indicators {
							display: none;
						}
					}
					.select__input {
						color: transparent;
					}
					.select__value-container {
						padding-left: 25px;
					}
					.select__indicators .select__dropdown-indicator {
						left: 7px;
						top: 8px;
						position: absolute;
					}
				}
			}
		}
		.empty {
			display: flex;
			flex-direction: column;
			align-items: center;
			img {
				max-width: 200px;
				padding-bottom: 36px;
			}
			p {
				font-family: UntitledSans-Regular;
				font-size: 20px;
				color: #5a5a61;
				line-height: 55px;
				letter-spacing: 0.15px;
				margin: 0;
			}
		}
		.add-btn {
			position: fixed;
			bottom: 60px;
			right: 60px;
			width: 50px;
			height: 50px;
			border-radius: 25px;
			color: #fff;
			background-color: #000;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			label {
				font-size: 30px;
				cursor: pointer;
			}
			input[type=file] {
				color: transparent;
				width: 0;
				height: 0;
				opacity: 0;
				position: absolute;
				left: 0;
				top: 0;
			}
			.empty-add-text {
				position: absolute;
				width: 110px;
				top: -130px;
				left: -130px;
				p {
					color: #000;
					font-family: SabonLTPro-Italic;
					font-size: 22px;
					letter-spacing: 0.45px;
					margin: 0;
					right: 45px;
					position: relative;
				}
				img {
					width: 129px;
					height: 129px;
				}
			}
		}
		.trash {
			position: absolute;
			top: 7px;
			right: 7px;
			z-index: 10;
			background-image: url(https://media-cf.wishi.me/react/icons/trash.png);
			background-size: 100%;
			background-position: center;
			width: 11px;
			height: 13px;
			opacity: 1;
		}
	}

	@media screen and (max-width: 576px) {
		>.row:first-of-type {
			position: relative;
			width: auto;
		}

		.results {
			padding: 25px 0;
			height: calc(100vh - 180px);

			.grid-item {
				&.large {
					width: calc(50% - 10px);
					height: 300px;
				}
				img {
					height: 255px;
				}
			}
			.results-bar {
				justify-content: center;
			}
			.add-btn {
				width: 70px;
				height: 70px;
				border-radius: 35px;
				bottom: 40px;
				right: 40px;
			}
		}
		.trash {
			opacity: 1;
		}
	}
}

.profile-closet {
	.results {
		.grid-item {
			&:hover {
				.trash {
					opacity: 1;
					cursor: pointer;
				}
			}
		}
	}
	@media screen and (max-width: 576px) {
		.results {
			.grid-item {
				.trash {
					opacity: 1;
				}
			}
		}
	}
}

.profile-looks {
	.subcategories {
		display: flex;
		p {
			font-size: 14px;
			letter-spacing: 0.82px;
			margin: 0 23px 0 0;
			cursor: pointer;
			&:hover, &.selected {
				text-shadow: 0 0 1px #000;
			}
		}
	}
	.board {
		width: calc(25% - 32px);
		border: 1px solid #f4f4f4;
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		margin: 0 32px 32px 0;
		padding: 17px;
		transition: all 200ms ease-in-out;
		position: relative;
		&.large {
			width: calc(33% - 30px);
			img {
				height: 340px;
			}
		}
		&:hover {
			.remove {
				display: block;
			}
		}
		.remove {
			width: 15px;
			height: 15px;
			position: absolute;
			top: 44px;
			right: 10px;
			cursor: pointer;
			display: none;
			&:before, &:after {
				position: absolute;
				content: '';
				height: 100%;
				width: 1px;
				background-color: #000;
			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
		}
		p {
			font-family: SabonLTPro-Italic;
			font-size: 16px;
			letter-spacing: 0.33px;
			height: 24px;
		}
		img {
			cursor: pointer;
			width: 100%;
			height: 238px;
			transition: all 200ms ease-in-out;
		}
	}
	@media screen and (max-width: 576px) {
		.results {
			padding: 25px 0;
			height: calc(100vh - 180px);
			.infinite-scroll-component {
				text-align: center;
				.board {
					width: 88%;
					margin: 5px auto;
					.remove {
						display: none;
					}
				}
			}
		}
	}
}

.profile-photos {
	> .row:first-of-type {
		position: relative;
		margin: 0 auto;
	}
	.results {
		padding: 25px 20px;
		.photo-item {
			position: relative;
			cursor: pointer;
			transition: all 200ms ease-in-out;

			&.large {
				height: 443px;
			}
			img {
				width: 100%;
				object-fit: cover;
				transition: all 200ms ease-in-out;
			}
			&:hover {
				.trash {
					opacity: 1;
					cursor: pointer;
				}
			}
		}
	}
	@media screen and (max-width: 576px) {
		>.row:first-of-type {
			position: relative;
			width: auto;
		}

		.results {
			padding: 25px 0;
			height: calc(100vh - 180px);

			.masonry .column {
				max-width: 50%;
			}
			.photo-item {
				height: 298px;
			}
		}
	}
}

.profile-orders {
	>.row {
		overflow: scroll;
	}
}

.profile-collections {
	> .row:first-of-type {
		position: relative;
	}
	.filters-column {
		.tag-filters {
			p {
				cursor: pointer;
			}
		}
	}
	.results {
		.selected-tag {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			margin-bottom: 24px;
			flex-direction: column;
			div {
				display: inline-flex;
				align-items: center;
			}
			label, h6 {
				font-size: 16px;
				line-height: 21px;
				letter-spacing: 0.33px;
				margin: 0;
			}
			label { font-family: UntitledSans-Medium; }
			h6 {
				font-family: UntitledSans-Regular;
				color: #8e8e93;
				padding-top: 5px;
			}
			.close {
				position: relative;
				top: 0;
				left: 0;
				width: 15px;
				height: 15px;
				margin-left: 14px;
				background: #000;
				border-radius: 50%;
				&:before, &:after {
					left: 7px;
					top: 4px;
					max-height: 7px;
					background-color: #fff;
				}
			}
		}
		.collection {
			width: calc(20% - 32px);
			height: 269px;
			display: inline-flex;
			flex-direction: column;
			margin: 0 32px 60px 0;
			transition: all 200ms ease-in-out;
			position: relative;
			&.large {
				width: 292px;
				height: 292px;
				.items {
					.collection-item {
						height: 50px;
						margin-right: 10px;
					}
				}
			}
			label {
				font-family: UntitledSans-Medium;
				font-size: 16px;
				line-height: 21px;
				letter-spacing: 0.33px;
				margin: 10px 0 0;
			}
			h6 {
				font-family: UntitledSans-Regular;
				color: #8e8e93;
				padding-top: 5px;
			}
			.items {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				border: 1px solid #f4f4f4;
				cursor: pointer;
				width: 100%;
				height: 100%;
				padding: 10px;
				.collection-image {
					height: 200px;
					width: 100%;
					object-fit: contain;
				}
				.collection-items {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					.collection-item {
						width: calc(20% - 5px);
						max-width: 50px;
						height: 40px;
						object-fit: contain;
						border: 1px solid #f4f4f4;
						transition: all 200ms ease-in-out;
					}
					p.collection-item {
						border: none;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						font-family: UntitledSans-Medium;
						font-size: 16px;
						margin: 0;
					}
				}
			}
		}
		.closet-item {
			margin: 0 16px 16px 0;
			width: calc(20% - 32px);
			min-width: 215px;
		}
	}
	@media screen and (max-width: 576px) {
		.results {
			.collection, .grid-item {
				width: calc(50% - 10px);
				margin: 0 5px 55px 0;
				&.large {
					width: calc(50% - 10px);
				}
				.items {
					.collection-image {
						height: 200px;
					}
				}
			}
		}
	}
}

.profile-empty {
	font-family: SabonLTPro-Italic;
	font-size: 22px;
	letter-spacing: 0.45px;
	width: 100%;
	color: #000;
	margin: 10px auto;
	text-align: center;
	img {
		width: 68px;
		height: 60px;
	}
}
