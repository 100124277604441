.grid-item {
	width: 215px;
	height: 300px;
	border: 1px solid #f4f4f4;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	margin: 5px;
	cursor: pointer;
	position: relative;
	transition: all 200ms ease-in-out;
	&.large {
		width: 356px;
		height: 443px;
		img {
			height: 366px;
		}
	}
	img {
		width: 100%;
		height: 215px;
		object-fit: contain;
		transition: all 200ms ease-in-out;
		position: relative;
		margin-right: 25px;
	}
	.loader {
		max-height: 230px;
		img {
			max-width: 60px;
		}
	}
	.details {
		height: 55px;
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		position: relative;
		p {
			font-family: UntitledSans-Regular;
			font-size: 12px;
			line-height: 17px;
			text-align: center;
			letter-spacing: 0.7px;
			margin: 0;
			min-height: 17px;
			&.title {
				font-family: UntitledSans-Medium;
			}
		}
		a {
			font-family: UntitledSans-Medium;
			font-size: 12px;
			letter-spacing: 0.7px;
			color: #000;
			text-decoration: underline;
			padding-top: 5px;
		}
		.sizes {
			display: none;
			font-family: UntitledSans-Medium;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 0 15px;
		}
	}
	.fav-star, .remove, .trash {
		position: absolute;
		top: 5px;
		right: 15px;
		z-index: 10;
	}
	.remove {
		width: 10px;
		height: 10px;
		&:before, &:after {
			position: absolute;
			content: '';
			height: 100%;
			width: 1px;
			background-color: #000;
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
	.fav-star {
		width: 8%;
		height: 8%;
		right:4px;
		display: block;
		background-size: 100%;
		opacity: 0;
		background-repeat: no-repeat;

		&.empty {
			background-image: url("data:image/svg+xml;utf8,<svg width='48' height='48' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' viewBox='0 0 24 24'><path d='M15.668 8.626l8.332 1.159-6.065 5.874 1.48 8.341-7.416-3.997-7.416 3.997 1.481-8.341-6.064-5.874 8.331-1.159 3.668-7.626 3.669 7.626zm-6.67.925l-6.818.948 4.963 4.807-1.212 6.825 6.068-3.271 6.069 3.271-1.212-6.826 4.964-4.806-6.819-.948-3.002-6.241-3.001 6.241z'/></svg>");

		}
		&.filled {
			opacity: 1;
			background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24' fill='rgb(248, 131, 121)'><path d='M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z'/></svg>");
		}
	}
	.trash {
		opacity: 0;
	}
	&:hover {
		.fav-star, .trash {
			opacity: 1;
			cursor: pointer;
		}
		&.shop-item {
			.details {
				.title, .price { display: none; }
				.sizes { display: block; }
			}

		}
	}
	.stockIcon{
		position: relative;
		visibility: hidden;
		right: 0px;
		z-index: 10;
		margin: 0px;
		width: 10px;
		height: 10px;
	
		&.notInStock{
			visibility: visible;
			top:20px;
			background-size: 100%;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml,%3Csvg%20width%3D'48'%20height%3D'48'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20class%3D'svg-iconx'%20viewBox%3D'0%200%2020%2020'%20%3E%3Cpath%20fill%3D'%23df0736'%20stroke%3D'%23df0736'%20d%3D'M13.864%2C6.136c-0.22-0.219-0.576-0.219-0.795%2C0L10%2C9.206l-3.07-3.07c-0.219-0.219-0.575-0.219-0.795%2C0%5C%0A%09%09%09%09%09%09%09%09c-0.219%2C0.22-0.219%2C0.576%2C0%2C0.795L9.205%2C10l-3.07%2C3.07c-0.219%2C0.219-0.219%2C0.574%2C0%2C0.794c0.22%2C0.22%2C0.576%2C0.22%2C0.795%2C0L10%2C10.795%5C%0A%09%09%09%09%09%09%09%09l3.069%2C3.069c0.219%2C0.22%2C0.575%2C0.22%2C0.795%2C0c0.219-0.22%2C0.219-0.575%2C0-0.794L10.794%2C10l3.07-3.07%5C%0A%09%09%09%09%09%09%09%09C14.083%2C6.711%2C14.083%2C6.355%2C13.864%2C6.136z%20M10%2C0.792c-5.086%2C0-9.208%2C4.123-9.208%2C9.208c0%2C5.085%2C4.123%2C9.208%2C9.208%2C9.208%5C%0A%09%09%09%09%09%09%09%09s9.208-4.122%2C9.208-9.208C19.208%2C4.915%2C15.086%2C0.792%2C10%2C0.792z%20M10%2C18.058c-4.451%2C0-8.057-3.607-8.057-8.057%5C%0A%09%09%09%09%09%09%09%09c0-4.451%2C3.606-8.057%2C8.057-8.057c4.449%2C0%2C8.058%2C3.606%2C8.058%2C8.057C18.058%2C14.45%2C14.449%2C18.058%2C10%2C18.058z'%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
		} 
		&.loading{
			visibility: visible;
			top: 0px;
			background-size: 55%;
			background-repeat: no-repeat;
			background-image: url("https://i.imgur.com/BAQCnDL.gif");
		}
		&.unknown{
			visibility: visible;
			top:20px;
			background-size: 100%;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 20 20'> <text x='6.5' y='15.5' fill='black' >?%3C/text%3E%3Cpath%20d=%27M10,0.562c-5.195,0-9.406,4.211-9.406,9.406c0,5.195,4.211,9.406,9.406,9.406c5.195,0,9.406-4.211,9.406-9.406C19.406,4.774,15.195,0.562,10,0.562%20M10,18.521c-4.723,0-8.551-3.829-8.551-8.552S5.277,1.418,10,1.418s8.552,3.828,8.552,8.551S14.723,18.521,10,18.521%27%3E%3C/path%3E%3C/svg%3E");
		}
		&.available{
			visibility: visible;
			top:20px;
			background-size: 100%;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 20 20'><path  fill%3D'%2319592C'%20 stroke%3D'%2319592C'%20d='M9.917,0.875c-5.086,0-9.208,4.123-9.208,9.208c0,5.086,4.123,9.208,9.208,9.208s9.208-4.122,9.208-9.208C19.125,4.998,15.003,0.875,9.917,0.875z%20M9.917,18.141c-4.451,0-8.058-3.607-8.058-8.058s3.607-8.057,8.058-8.057c4.449,0,8.057,3.607,8.057,8.057S14.366,18.141,9.917,18.141z%20M13.851,6.794l-5.373,5.372L5.984,9.672c-0.219-0.219-0.575-0.219-0.795,0c-0.219,0.22-0.219,0.575,0,0.794l2.823,2.823c0.02,0.028,0.031,0.059,0.055,0.083c0.113,0.113,0.263,0.166,0.411,0.162c0.148,0.004,0.298-0.049,0.411-0.162c0.024-0.024,0.036-0.055,0.055-0.083l5.701-5.7c0.219-0.219,0.219-0.575,0-0.794C14.425,6.575,14.069,6.575,13.851,6.794z'></path></svg>");
		}
	}

	.sizeAvailability{
		position: absolute;
		visibility: hidden;
		top: 12px;
		right: 4px;
		z-index: 10;
		width: 12px;
		height: 12px;
		font-size: 9px;
		text-align: center;
		white-space: nowrap;
		&.show{
			visibility: visible;
		}

	}
	.iconsBar{
		display: grid;
		right: 3px;
		position: absolute;
		grid-template-rows: 30px;
		grid-auto-rows: 30px;
		grid-template-columns: 50%;
		top:7px;
		/*column-gap: 23px;*/
		/*width: 15%;*/
	}


	@media screen and (max-width: 576px) {
		width: calc(50% - 10px);
		justify-content: space-between;

		img {
			height: 255px;
		}
		.trash {
			opacity: 1;
			font-size: 18px;
			background: #fff;
		}
		.details {
			height: 34px;
			p {
				line-height: 17px;
				letter-spacing: 0.7px;
				&.title {
					font-family: UntitledSans-Regular;
					text-transform: uppercase;
				}
			}
		}
	}
}
