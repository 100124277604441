.remove-photo {
	width: 100%;
	margin: 0 auto;
	text-align: center;

	.modal-header {
		p {
			font-family: UntitledSans-Medium;
			font-size: 20px;
			line-height: 22px;
			letter-spacing: 0.64px;
		}
	}

	.modal-footer {
		button {
			width: 113px;
			height: 33px;
			font-size: 14px;
			letter-spacing: 1px;
			border-radius: 0;

			&:focus, &:hover {
				border: none;
			}
		}
	}
}