.page {
	height: 100%;
	&.with-header {
		padding-top: 0;
		//overflow-x: hidden;
	}

	&.fixed-header {
		padding-top: 68px;

	}

	&.no-footer {
		height: 100vh;
	}
	&.chat{
		padding-top: 45px;
	}

	@media screen and (max-width: 576px){
		&.fixed-header {
			padding-top: 55px;
		}
	}
}
