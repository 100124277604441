.profile {
	position: absolute;
	height: calc(100vh - 70px);
	.header {
		height: 90px;
		width: 100%;
		display: flex;
		align-items: center;
		position: fixed;
		border-bottom: 1px solid #f4f4f4;
		background: #fff;
		z-index: 10;
		padding-left: 40px;

		.user {
			display: flex;
			align-items: center;
			height: 100%;
			max-width: 20%;
			min-width: 220px;
			font-family: UntitledSans-Medium;
			.user-name {
				display: inline-flex;
				align-items: center;
				justify-content: flex-start;
				padding-left: 15px;
				.name {
					font-size: 16px;
					letter-spacing: 0.91px;
					margin: 0;
					text-transform: capitalize;
					display: flex;
				}
				.level {
					width: 25px;
					height: 25px;
					background-size: 100%;
					background-repeat: no-repeat;
					margin: 0 15px;
					&.platinum {
						background-image: url(https://media-cf.wishi.me/react/icons/member-platinum.png);
					}
					&.gold {
						background-image: url(https://media-cf.wishi.me/react/icons/member-gold.png);
					}
					&.bronze {
						background-image: url(https://media-cf.wishi.me/react/icons/member-bronze.png);
					}
				}
			}
			.settings-btn {
				width: 18px;
				height: 18px;
				background-image: url(https://media-cf.wishi.me/react/icons/settings.png);
				background-size: 100%;
				display: inline-flex;
			}
		}
		.menu {
			display: flex;
			align-items: center;
			.header-menu-item {
				display: flex;
				flex-direction: row;
				padding-right: 20px;
				font-family: UntitledSans-Regular;
				font-size: 14px;
				letter-spacing: 0.82px;
				cursor: pointer;
				p {
					padding: 0 5px 0 0;
					margin: 0;
					order: 1;
				}
				span {
					order: 2;
					&:before { content: '('; }
					&:after { content: ')'; }
				}
				&:hover, &.active {
					p, span {
						text-shadow: 0 0 1px #000;
					}
				}
			}
		}
		.grid-size {
			display: flex;
			position: absolute;
			right: 0;
			.size-btn {
				cursor: pointer;
				width: 32px;
				height: 32px;
				border-radius: 2px;
				margin-right: 7px;
				background-repeat: no-repeat;
				background-size: 80%;
				background-position: center center;
				opacity: 0.5;
				transition: opacity 100ms ease-in-out;

				&.active, &:hover {
					opacity: 1;
				}

				&.large {
					background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 9.5 9.5' xml:space='preserve'%3E%3Cg%3E%3Crect x='0' y='0' style='fill:%23030104;' width='2.5' height='10'/%3E%3C/g%3E%3Cg%3E%3Crect x='3.5' y='0' style='fill:%23030104;' width='2.5' height='10'/%3E%3C/g%3E%3Cg%3E%3Crect x='7' y='0' style='fill:%23030104;' width='2.5' height='10'/%3E%3C/g%3E%3C/svg%3E%0A");
				}

				&.small {
					background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 6.5 6.5' xml:space='preserve'%3E%3Cg%3E%3Crect x='0' y='0' style='fill:%23030104;' width='0.5' height='10'/%3E%3C/g%3E%3Cg%3E%3Crect x='1.5' y='0' style='fill:%23030104;' width='0.5' height='10'/%3E%3C/g%3E%3Cg%3E%3Crect x='3' y='0' style='fill:%23030104;' width='0.5' height='10'/%3E%3C/g%3E%3Cg%3E%3Crect x='4.5' y='0' style='fill:%23030104;' width='0.5' height='10'/%3E%3C/g%3E%3Cg%3E%3Crect x='6' y='0' style='fill:%23030104;' width='0.5' height='10'/%3E%3C/g%3E%3C/svg%3E%0A");
				}
			}
		}
	}

	.content {
		padding-top: 90px;
		height: 100%;
		width: 100%;
		overflow: hidden;
		margin: 0 auto;
	}

	@media screen and (max-width: 576px) {
		.header {
			flex-direction: column;
			height: 120px;
			padding-left: 0;
			.user {
				max-width: 100%;
				max-height: 70px;
				padding: 0;
				margin: 32px auto;
				font-family: UntitledSans-Regular;
				.profile-photo {
					width: 36px;
					height: 36px;
					border-radius: 18px;
				}
				.user-name {
					width: calc(100% - 40px);
					.name {
						font-size: 18px;
						letter-spacing: 0.33px;
					}
				}
			}
			.menu {
				padding: 0;
				.header-menu-item {
					flex-direction: column;
					align-items: center;
					justify-content: flex-end;
					height: 100%;
					width: 25%;
					padding: 0 25px;
					font-family: UntitledSans-Regular;
					span {
						order: 1;
						&:before, &:after { content: ''; }
					}
					p {
						order: 2;
						padding: 0 0 5px 0;
					}
					&.active {
						border-bottom: 2px solid #FA7268;
						font-family: UntitledSans-Medium;
						p, span {
							text-shadow: none;
						}
					}
				}
			}
		}
		.content {
			padding-top: 120px;
			> .col {
				padding: 0;
			}
		}
	}
}