.page-message {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	width: 100%;
	background: #000;
	position: relative;
	transition: height 200ms ease-in-out;
	overflow: hidden;

	&.show {
		height: 45px;
	}
	&.hide {
		height: 0;
	}

	p {
		font-family: UntitledSans-Regular;
		text-align: center;
		font-size: 16px;
		letter-spacing: 0.06px;
		color: #fff;
		margin: 0;
	}
	.close {
		top: 15px;
		right: 20px;
		width: 15px;
		height: 15px;
		left: auto;
		position: absolute;
		&:before, &:after {
			width: 2px;
			background-color: #fff;
		}
	}
	@media screen and (max-width: 576px) {
		background: #fff;
		height: auto;
		&.show {
			height: 96px;
		}
		p {
			padding: 12px 40px;
			font-family: UntitledSans-Regular;
			font-size: 16px;
			color :#000;
		}
		.close {
			&:before, &:after {
				width: 1px;
				background-color: #000;
			}
		}
	}
}