.settings {
	background-color: #F4F4F4;
	padding: 40px 0;
	min-height: calc(100vh - 70px);

	@media screen and (max-width: 576px) {
		padding: 22px 0;
		min-height: calc(100vh - 55px);
		> .container {
			padding: 0;
			margin: 0;
			width: 100%;
			max-height: 100%;
			overflow: auto;
		}
	}
}