.landing {
	overflow: hidden;
	width: 400px;
	.form-label{
		font-family: UntitledSans;
		font-size: 12px;
		text-align: left;
		color: #4a4a4a;
		text-transform: uppercase;
		margin-bottom: 3px;
		letter-spacing: 1.1px;
	}
	.form-check{
		padding-left: 0;
	}
	.form-row{
		justify-content: center;
	}
	.submit-btn{
		width: 97%;
	}
	.modal-header{
		border-bottom: none;
	}
}