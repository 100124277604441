.radio-button {
	&.form-check {
		margin-bottom: 32px;
		white-space: nowrap;
		padding: 0;
	}

	&.vertical {
		text-align: center;
		margin: 0;
		.form-check-label {
			padding: 0;

			&:before {
				position: relative;
				display: block;
				margin: 8px auto 13px;
			}

			@media screen and (max-width: 576px) {
				font-size: 16px;

				&:before {
					width: 21px;
					height: 21px;
				}
			}
		}
	}

	&.light {
		&.checked .form-check-label:before {
			background: #F88379;
		}
	}

	&.dark {
		&.checked .form-check-label:before {
			background: #000;
		}
		&.dot {
			&.checked .form-check-label {
				&:before {
					background: transparent;
				}
				&:after {
					content: '';
					position: absolute;
					border-radius: 100%;
					transition: all 500ms;
					top: 5px;
					left: 5px;
					width: 15px;
					height: 15px;
					background: #000;
				}
			}
		}
	}

	&.checked .form-check-label {
		font-weight: 500;
	}

	input {
		display: none;
	}

	.form-check-label {
		font-family: UntitledSans;
		cursor: pointer;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: normal;
		color: #000;
		text-transform: uppercase;
		padding-left: 40px;
		white-space: pre-wrap;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			border-radius: 100%;
			transition: all 500ms;
			width: 25px;
			height: 25px;
			border: 1px solid #000;
			background: transparent;
		}
	}

	@media screen and (max-width: 576px) {
		&.dark {
			&.dot {
				input:checked + .form-check-label {
					&:after {
						content: '';
						position: absolute;
						border-radius: 100%;
						transition: all 500ms;
						top: 7px;
						left: 4px;
						width: 13px;
						height: 13px;
						background: #000;
					}
				}
			}
		}

		.form-check-label {
			font-size: 18px;

			&:before {
				width: 21px;
				height: 21px;
				top: 3px;
			}
		}
	}
}