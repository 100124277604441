.canvas {
	background: #fff;
	position: fixed;
	bottom: 21px;
	right: 46px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 999;

	&.closed {
		.canvas-wrapper {
			z-index: 1;
			opacity: 0;
			height: 0;
			width: 0;
			right: -100px;
		}
		.minimized-buttons {
			z-index: 2;
			opacity: 1;
			height: auto;
			width: auto;
			right: 0;
		}
	}

	&.canvas-type-personal {
		.buttons .layout {
			.layout-btn.grid {
				display: none;
			}
		}
	}

	.canvas-wrapper {
		transition: all 200ms ease-in-out;
		opacity: 1;
		z-index: 2;
		position: absolute;
		bottom: 10px;
		right: 0;
		background: #fff;
		.preview {
			width: 100%;
			height: 100%;
			img {
				&.resized {
					max-width: 200%;
					max-height: 200%;
				}
				max-width: 100%;
				max-height: 100%;
			}
		}
		.show {
			display: block;
		}
		.hide {
			display: none;
		}
		.board {
			width: 100%;
			max-height: calc(100% - 60px);
			.canvas-container {
				max-height: 100%;
			}
		}
	}
	.minimized-buttons {
		z-index: 1;
		transition: all 200ms ease-in-out;
		opacity: 0;
		height: 0;
		width: 0;
		position: absolute;
		bottom: 10px;
		right: -100px;
		&.show {
			opacity: 1;
		}
		.buttons {
			border: none;
			.layout {
				.layout-btn {
					position: relative;
					width: 50px;
					height: 50px;
					background: #000;
					border-radius: 25px;
					display: flex;
					align-items: center;
					justify-content: center;
					&.grid {
						padding: 14px;
						span {
							width: 6px;
							height: 6px;
							border: 2px solid #fff;
						}
					}
					&.free {
						margin-right: 12px;
						border: none;
						&:after {
							content: '';
							width: 20px;
							height: 20px;
							border: 2px solid #fff;
							display: block;
						}
					}
					.items-counter {
						width: 30px;
						height: 30px;
						background-color: #e02020;
						border-radius: 15px;
						position: absolute;
						font-family: UntitledSans-Medium;
						font-size: 16px;
						letter-spacing: 0.93px;
						color: #fff;
						top: -10px;
						left: -10px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}

	.sizeToggle {
		width: 27px;
		height: 27px;
		border: 1px solid #979797;
		border-radius: 50%;
		position: absolute;
		top: -30px;
		background: #fff;
		cursor: pointer;
		z-index: 5;
		display: flex;
		align-items: center;
		justify-content: center;

		&:before {
			content: '';
			display: block;
		}

		&.resize {
			left: 35px;
			font-size: 11px;
			&:before {
				position: relative;
				top: -4px;
				content: '\2196';
			}
			&:after {
				position: relative;
				top: 4px;
				content: '\2198';
			}
		}

		&.close {
			left: 0;
			&:before, &:after {
				top: 7px;
				left: 12px;
				width: 1px;
				height: 12px;
				background-color: #000;
			}
		}

		&.disabled {
			pointer-events: none;
			&:before {
				opacity: .5;
			}
		}
	}
	.loader {
		z-index: 3;
	}
	.board, .canvas-grid, .preview {
		max-width: 90vw;
		max-height: 90vh;
		border: 1px solid #979797;
		z-index: 1;
		transition: all 200ms ease-in-out;
		overflow: hidden;

		@media (width: 45px) {
			background-color: red;
		}
	}
	.canvas-grid {
		padding: 10px;
		.container-fluid, .row {
			height: 100%;
		}
		.row {
			overflow-y: scroll;
		}
		.canvas-grid-item {
			height: 33%;
			width: 100%;
			padding: 5px;
			overflow: hidden;
			&:hover {
				.canvas-grid-remove {
					opacity: 1;
				}
			}
			&.empty {
				span {
					display: block;
					width: 100%;
					height: 100%;
					border: 1px dashed #dbdbdb;
				}
				.canvas-grid-remove {
					display: none;
				}
			}
			img {
				border: 1px solid #dbdbdb;
				width: 100%;
				height: 100%;
				object-fit: contain;
				transition: all 200ms ease-in-out;
				&.with-details {
					padding-bottom: 55px;
				}
			}
			.details {
				height: 45px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				position: absolute;
				bottom: 10px;
				width: 100%;
				p {
					font-family: UntitledSans-Regular;
					font-size: 12px;
					line-height: 17px;
					text-align: center;
					letter-spacing: 0.7px;
					margin: 0;
					min-height: 17px;
					cursor: pointer;
					&.title {
						font-family: UntitledSans-Medium;
					}
				}
				a {
					font-family: UntitledSans-Medium;
					font-size: 12px;
					letter-spacing: 0.7px;
					color: #000;
					text-decoration: underline;
					z-index: 2;
				}
			}
			.canvas-grid-remove {
				background-color: #fff;
				width: 16px;
				height: 16px;
				position: absolute;
				top: 0;
				right: 0;
				border: 1px solid #dbdbdb;
				border-radius: 8px;
				cursor: pointer;
				opacity: 0;
				&:before, &:after {
					content: '';
					height: 8px;
					border-left: 1px solid #000;
					position: absolute;
					left: 7px;
					top: 3px;
				}
				&:before {
					transform: rotate(-45deg);
				}
				&:after {
					transform: rotate(45deg);
				}
			}
		}
	}
	.toolbar {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		background: #000;
		height: 35px;
		width: 230px;
		z-index: 9999;
		padding: 0 10px;
		color: #fff;
		font-size: 14px;

		&.disabled {
			opacity: .3;
			button {
				pointer-events: none;
			}
		}

		svg {
			width: 16px;
			margin-right: 5px;
		}

		button {
			width: 18px;
			height: 18px;
			background-color: transparent;
			padding: 0;
			margin: 0 7px;
			background-size: 18px;
			background-repeat: no-repeat;
			background-position: center;

			&.remove {
				background-image: var(--canvas-toolbar-remove);
			}
			&.backward {
				background-image: var(--canvas-toolbar-backward);
				background-size: 10px;
			}
			&.forward {
				background-image: var(--canvas-toolbar-forward);
				background-size: 10px;
			}
			&.crop {
				background-image: var(--canvas-toolbar-crop)
			}
			&.flip {
				background-image: var(--canvas-toolbar-flip)
			}
			&.flop {
				background-image: var(--canvas-toolbar-flop)
			}
			&.remove-background {
				background-image: var(--canvas-toolbar-remove-background);
			}
			&.undo-remove-background {
				background-image: var(--canvas-toolbar-undo-remove-background)
			}
			&.disabled {
				pointer-events: none;
				opacity: .3;
				cursor: none;
			}
		}
	}
	.buttons {
		height: 60px;
		width: 100%;
		border: 1px solid #979797;
		border-top: none;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 15px;
		.total {
			font-family: UntitledSans-Medium;
			font-size: 12px;
			letter-spacing: 0.7px;
			width: 100%;
			height: 80%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 20px;
			border-right: 1px solid #dbdbdb;
		}
		.layout {
			display: flex;
			.layout-btn {
				width: 30px;
				height: 30px;
				cursor: pointer;
				border-color: #dbdbdb;
				&.free {
					border: 1px solid #dbdbdb;
				}
				&.grid {
					display: flex;
					flex-flow: wrap;
					justify-content: space-evenly;
					margin-right: 18px;
					span {
						width: 8px;
						height: 8px;
						border: 1px solid #dbdbdb;
						transition: border-color 300ms;
					}
				}
				&:hover, &.on {
					border-color: #000;
					span {
						border-color: #000;
					}
				}
			}
		}
		.save {
			font-family: UntitledSans-Medium;
			font-size: 14px;
			letter-spacing: 0.7px;
			cursor: pointer;
			width: 40px;
			margin-left: 20px;
			text-align: right;

			&:hover {
				font-weight: bold;
			}

			&.disabled {
				opacity: .3;
				pointer-events: none;
			}
		}
	}
}

#canvas-toolbar-tooltip {
	margin-bottom: 15px;

	.tooltip-inner {
		padding: 5px 7px;
		border-radius: 2px;
		font-family: UntitledSans-Medium;
		font-size: 14px;
		background-color: #262626;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
	}
	.arrow {
		&:before {
			border-top-color: #262626;
			border-bottom-color: #262626;
		}
	}

}

.modal.canvas-crop-modal {
	max-height: 100%;
	.modal-dialog {
		max-height: 100vh;
		max-width: 451px;
	}
	.modal-header {
		.modal-title {
			font-family: UntitledSans-Medium;
			font-size: 20px;
			letter-spacing: 0.51px;
			color: #000;
			padding-bottom: 16px;
		}
	}
	.modal-body {
		min-height: 150px;
		min-width: 150px;
		border: 1px dashed #dbdbdb;
		padding: 10px;
		img {
			object-fit: contain;
		}
		.cropped {
			display: none;
		}
	}
	.modal-footer {
		justify-content: space-between;
		padding-top: 13px;
		button {
			font-family: UntitledSans-Medium;
			font-size: 16px;
			line-height: 35px;
			letter-spacing: 0.5px;
			width: 180px;
			text-transform: none;
			&.btn-light {
				font-family: UntitledSans-Regular;
				border: 1px solid #000;
			}
		}
	}
}