.page-navbar {
	position: relative;
	.back {
		position: absolute;
		left: 0;
		top: 15px;

		svg {
			cursor: pointer;
			width: 14px;
			height: 14px;
		}
	}

	.row {
		margin: auto 20px;
		.anchor {
			font-family: UntitledSans-Regular;
			font-size: 12px;
			letter-spacing: 0.29px;
			padding: 20px 0 0;
			text-align: center;
			text-transform: uppercase;
		}
	}
}