.lightbox {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999;

	&.dark {
		background-color: rgba(0, 0, 0, .8);
	}

	&.light {
		background-color: rgba(255, 255, 255, .8);
	}

	&.opaque {
		&.dark {
			background-color: #000;
		}
		&.light {
			background-color: #fff;
		}
	}

	.close {
		width: 37px;
		height: 37px;
		background-color: #fff;
		border-radius: 20px;
		&:before, &:after {
			height: 13px;
			left: 18px;
			top: 12px;
		}
		&.left {
			left: 45px;
			right: auto;
		}
		&.right {
			left: auto;
			right: 45px;
		}
		&:not(:disabled):not(.disabled):hover {
			opacity: 1;
		}
	}

	.lightbox-carousel {
		min-width: 100px;
		max-width: 570px;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.slick-list {
			width: 80%;
			.slick-slide {
				img {
					max-height: 80vh;
					object-fit: contain;
					cursor: pointer;
				}
			}
		}
		.slick-next,
		.slick-prev {
			width: 34px;
			height: 34px;
			border-radius: 17px;
			background-color: #fff;
			border: 1px solid #979797;
			z-index: 99;
			&:before {
				position: relative;
				content: '';
				display: inline-block;
				width: 10px;
				height: 10px;
				border-right: 2px solid black;
				border-top: 2px solid black;

			}
		}
		.slick-prev {
			left: -10px;
			&:before {
				left: 2px;
				transform: rotate(225deg);
			}
		}
		.slick-next {
			right: -10px;
			&:before {
				right: 2px;
				transform: rotate(45deg);
			}
		}
	}
}