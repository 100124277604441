.toggle-button {
	&.btn-group {
		display: flex;
		height: 43px;
		width: 309px;
		margin: 6px auto 24px;
		button {
			font-family: UntitledSans-Regular;
			font-size: 12px;
			letter-spacing: 1px;
			padding: 11px 0;
			text-align: center;
			width: 50%;
			border-radius: 4px;
			border-color: #dbdbdb;
			&.selected {
				font-family: UntitledSans-Medium;
				color: #fff;
				background-color: #000;
				border-color: #000;
			}
			&:focus {
				border-color: #000;
			}
		}
	}
}