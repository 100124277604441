.add-item {
	width: 100%;
	margin: 0 auto;

	.modal-header {
		height: 25px;
		.close {
			left: auto !important;
			right: 30px;
		}
	}
	.error{
		color: red;
		max-width: 270px !important;
	}
	.modal-body {
		.dropzone {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 287px;
			height: 438px;
			border: 1px solid #dbdbdb;
			font-family: UntitledSans-Regular;
			font-size: 12px;
			line-height: 17px;
			letter-spacing: 0.7px;
			cursor: pointer;

			p {
				max-width: 114px;
				text-align: center;
			}
			strong {
				font-family: UntitledSans-Medium;
				font-size: 20px;
				padding-bottom: 5px;
			}
		}
		.preview {
			.remove-image {
				position: absolute;
				top: -10px;
				right: 10px;
				width: 20px;
				height: 20px;
				background: #fff;
				border-radius: 10px;
				box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
				cursor: pointer;
				transition: box-shadow 200ms ease-in-out;
				&:hover {
					box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
				}
				&:before, &:after {
					position: absolute;
					content: '';
					height: 60%;
					width: 1px;
					background-color: #000;
				}
				&:before {
					left: 10px;
					top: 4px;
					transform: rotate(45deg);
				}
				&:after {
					left: 10px;
					top: 4px;
					transform: rotate(-45deg);
				}
			}
			img {
				width: 100%;
			}
		}
		form {
			width: 283px;
			.form-control:focus {
				box-shadow: none;
			}
			.accordion {
				.form-label {
					position: relative;
					cursor: pointer;
					.arrow {
						position: absolute;
						right: -20px;
						top: 10px;
						width: 0;
						height: 0;
						border-left: 5px solid transparent;
						border-right: 5px solid transparent;
						border-top: 5px solid #000;
						transition: transform 200ms ease-in-out;
						&.open {
							transform: rotate(180deg);
						}
					}
				}
			}
			.form-group {
				width: 100%;
				label {
					font-family: UntitledSans-Regular;
					font-size: 14px;
					text-transform: capitalize;
					color: #000;
					margin-bottom: 5px;
				}
				input, select {
					font-family: UntitledSans-Medium;
					border: 1px solid #dbdbdb;
					border-radius: 4px;
					font-size: 14px;
					letter-spacing: 0.29px;
					height: 40px;
					color: #000;

					&.placeholder {
						color: #7B8083;
					}
				}
				.category {
					position: relative;
					select {
						appearance: none;
						padding-right: 30px;
					}
					&:after {
						content: '';
						width: 30px;
						height: 100%;
						position: absolute;
						top: 0;
						right: 0;
						display: block;
						pointer-events: none;
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 21l-12-12h24z'/%3E%3C/svg%3E");
						background-size: 10px;
						background-repeat: no-repeat;
						background-position: right 15px center;
					}
				}

				.seasons {
					display: flex;
					flex-direction: row;
					.season {
						font-family: UntitledSans-Regular;
						font-size: 12px;
						letter-spacing: 0.29px;
						color: #4a4a4a;
						width: 61px;
						height: 61px;
						text-align: center;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: space-evenly;
						margin-right: 5px;
						cursor: pointer;
						transition: font-size 50ms;
						text-transform: capitalize;

						.season-icon {
							width: 29px;
							height: 29px;
							background-size: 29px;
							background-repeat: no-repeat;

							&.summer { background-image: url(https://media-cf.wishi.me/react/icons/icon-summer.png); }
							&.fall { background-image: url(https://media-cf.wishi.me/react/icons/icon-fall.png); }
							&.winter { background-image: url(https://media-cf.wishi.me/react/icons/icon-winter.png); }
							&.spring { background-image: url(https://media-cf.wishi.me/react/icons/icon-spring.png); }
						}

						&.selected {
							border-radius: 2px;
							font-family: UntitledSans-Medium;
							font-size: 14px;
							color: #000;
						}
					}
				}
				.colors {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					.color {
						width: 20px;
						height: 20px;
						margin: 0 10px 10px 0;
						border-radius: 3px;
						cursor: pointer;
						border: 1px solid #dbdbdb;
						transition: box-shadow 200ms ease-in-out;
						object-fit: cover;
						&.selected {
							box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.7);
							border: none;
						}
					}
				}
			}
			button {
				width: 100%;
				max-width: 283px;
				height: 50px;
				letter-spacing: 1px;

				&:focus, &:hover {
					border: none;
				}
			}
		}
	}

	@media screen and (max-width: 576px) {
		.modal-body {
			.dropzone {
				width: 100%;
				margin-bottom: 22px;
			}
			.preview {
				width: 100%;
				padding-top: 10px;
				margin-bottom: 22px;
				.remove-image {
					top: 10px;
					width: 41px;
					height: 41px;
					border-radius: 22px;
					&:before, &:after {
						left: 20px;
						top: 9px;
					}
				}
				img {
					min-width: 287px;
				}
			}
			form {
				width: 100%;
				.form-group {
					label {
						font-size: 18px;
					}
					select, input {
						height: 46px;
						font-size: 18px;
					}
					.colors {
						.color {
							width: 45px;
							height: 45px;
						}
					}
					.seasons {
						justify-content: space-evenly;
						.season {
							width: 70px;
							height: 70px;
						}
					}
				}
				button {
					max-width: 100%;
				}
			}
		}
	}
}