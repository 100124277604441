.error {
	width: 100%;
	max-width: 300px;
	margin: 0 auto;
	text-align: center;

	.modal-header {
		p {
			font-family: UntitledSans-Medium;
			font-size: 20px;
			line-height: 22px;
			letter-spacing: 0.64px;
		}
		.close {
			height: 8px;
			right: 8px;
			top: 8px !important;
			left: auto !important;
		}
	}
	.modal-body {
		p {
			font-family: UntitledSans-Regular;
			font-size: 14px;
			letter-spacing: 0.7px;
		}
	}

	.modal-footer {
		button {
			width: 113px;
			height: 33px;
			font-size: 14px;
			letter-spacing: 1px;
			border-radius: 0;
		}
	}
}