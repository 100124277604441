.signin-modal {
	padding-top: 20px;
	max-width: 390px;
	width: 100%;
	margin: 0 auto;

	strong {
		cursor: pointer;
	}

	.email-domains {
		padding-bottom: 10px;
		flex-wrap: nowrap;
		overflow: scroll;
		width: 100%;
		margin: 0;

		.badge-secondary {
			font-family: UntitledSans;
			font-weight: 400;
			background-color: #f4f4f4;
			color: #000;
			font-size: 12px;
			line-height: 20px;
			letter-spacing: 0.35px;
			cursor: pointer;
			margin: 0 5px;
		}
	}

	.forgot-pass {
		font-family: UntitledSans;
		font-size: 12px;
		font-weight: bold;
		color: #000;
		text-align: right;
	}

	.invalid-feedback {
		display: block;
		transition: all 200ms;
	}

	.btns-divider {
		font-family: UntitledSans;
		margin: 15px auto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.9px;
		justify-content: center;
		color: #4a4a4a;
	}
}