.input {
	&.container {
		max-width: 400px;
		padding: 0;
	}

	.input-group-text {
		background-color: transparent;
	}
	.input-group-append {
		.input-group-text {
			border-left: 0;
		}
	}
	.input-group-prepend {
		.input-group-text {
			border-right: 0;
		}
	}

	&.social-input-field {
		.form-control {
			border-right: 0;
			border-left: 0;
			&:focus {
				border-color: #dbdbdb;
				box-shadow: none;
			}
		}
	}

	&.dropdown-input-field {
		select.form-control {
			&:active, &:focus {
				box-shadow: none;
				outline: none;
			}
		}
	}

	input {
		font-family: UntitledSans-Regular;
		height: 47px;
		border-radius: 0;
		border: 1px solid #dbdbdb;
		color: #000;
		&::placeholder {
			font-family: UntitledSans-Regular;
			color: #6d7278;
		}
		&:focus {
			box-shadow: none;
		}
		&.empty {
			color: #fff;
			&:focus {
				color: #000;
			}
		}
	}

	input[type="checkbox"] {
		+ label {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			letter-spacing: 0.89px;
			line-height: 35px;
			padding: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&:before {
				border-radius: 50%;
				border-width: 1px;
			}
		}

		&:checked + label {
			&:before {
				background: #fff;
			}

			&:after {
				display: block;
				width: 9px;
				height: 9px;
				background: #000;
				border: 0;
				border-radius: 4px;
				top: 13px;
				right: 0;
				left: 8px;
			}
		}
	}

	.color {
		img {
			width: 80px;
			height: 40px;
			object-fit: contain;
			cursor: pointer;
		}

		&.selected {
			&:before {
				content: '';
				display: block;
				position: absolute;
				color: #000;
				width: 22px;
				height: 22px;
				background: #000;
				border-radius: 50%;
				top: 9px;
				left: 50%;
				margin-left: -11px;
				cursor: pointer;
			}
			&:after {
				content: "";
				position: absolute;
				top: 14px;
				left: calc(50% - 2px);
				color: #fff;
				transform: rotate(45deg);
				height: 10px;
				width: 5px;
				border-bottom: 2px solid #fff;
				border-right: 2px solid #fff;
				cursor: pointer;
			}
		}
	}

	.input-location {
		font-family: UntitledSans-Medium;
		color: #000;
	}

	.intl-tel-input {
		&.allow-dropdown {
			width: 100%;
			.selected-flag {
				outline: none;
			}
			.flag-container {
				width: auto;
				border: 1px solid #dbdbdb;
				.arrow {
					&.down, &.up {
						&:after {
							width: 8px;
							height: 8px;
						}
					}
				}
			}
			input[type=tel] {
				width: calc(100% - 62px);
				margin-left: 62px;
				padding: 10px 15px;
				border: 1px solid #dbdbdb;
				font-family: UntitledSans-Medium;
				font-size: 16px;
				letter-spacing: 1.8px;
				outline: none;
			}
		}

	}

	.error {
		font-family: UntitledSans-Regular;
		font-size: 14px;
		letter-spacing: 0.35px;
		color: #e02020;
		padding: 0 7px;
	}

	@media screen and (max-width: 576px) {
		&.container {
			padding: 0 7px;
		}
		.input {
			.color {
				width: 40px;
			}
		}
	}
}

.customFormGroup{
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	align-items: start;
}

.customFormLabel{
	display: inline-block;
    margin-bottom: 0.5rem;
    font-weight: 500;
	font-size: 14px;
    font-family: 'UntitledSans-Regular';
    letter-spacing: 1.25px;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
    text-align: left;
	font-size: 14px;
    letter-spacing: 1.25px;
}