.sticky-button {
	background-color: #fff;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 999;
	transition: bottom 200ms ease-in-out;
	@media screen and (max-width: 576px) {
		left:0;
	}

	button {
		margin: 10px auto;
		display: flex;
		width: 80%;
		justify-content: center;
	}

	&.keyboard {
		bottom: 290px;
	}

	&.disabled {
		pointer-events: none;
	}
}