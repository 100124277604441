.toggle-switch {
	position: relative;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 24px;

	input {
		opacity: 0;
		width: 0;
		height: 0;

		&:checked + .slider:before {
			transform: translateX(24px);
		}
	}

	.slider {
		position: relative;
		cursor: pointer;
		background-color: #000;
		transition: .4s;
		border-radius: 12px;
		width: 48px;
		height: 24px;

		&:before {
			position: absolute;
			content: "";
			height: 20px;
			width: 20px;
			left: 2px;
			bottom: 2px;
			background-color: white;
			transition: .4s;
			border-radius: 50%;
		}
	}

	p {
		font-family: UntitledSans-Medium;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 1px;
		color: #6C6C6C;
		font-weight: 300;
		transition: all .4ms ease-in-out;
		margin: 0;

		&.on {
			font-weight: 700;
		}
	}
}