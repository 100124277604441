:root {
    --logo-black: url(https://media-cf.wishi.me/react/landing/wishi_black.svg);
    --icon-cart: url(https://media-cf.wishi.me/react/icons/cart.svg);
    --our-story-header-bg: url(https://media-cf.wishi.me/react/our-story/our-story-bg.jpg);
    --canvas-toolbar-remove: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-remove.png);
    --canvas-toolbar-backward: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-backward.png);
    --canvas-toolbar-forward: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-forward.png);
    --canvas-toolbar-crop: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-crop.png);
    --canvas-toolbar-flip: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-flip.png);
    --canvas-toolbar-flop: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-flop.png);
    --canvas-toolbar-remove-background: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-remove-background.png);
    --canvas-toolbar-undo-remove-background: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-undo-remove-background.png);
    --gifts-header-bg: url(https://media-cf.wishi.me/react/campaigns/gifts/header-bg.png);
    --gifts-karla-1: url(https://media-cf.wishi.me/react/campaigns/gifts/karla.jpg);
    --gifts-karla-2: url(https://media-cf.wishi.me/react/campaigns/gifts/karla2.jpg);
    --gifts-karla-3: url(https://media-cf.wishi.me/react/campaigns/gifts/karla3.png);
    --gifts-karla-4: url(https://media-cf.wishi.me/react/campaigns/gifts/karla4.png);
    --gifts-clea-1: url(https://media-cf.wishi.me/react/campaigns/gifts/clea1.png);
    --gifts-clea-2: url(https://media-cf.wishi.me/react/campaigns/gifts/clea2.png);
    --gifts-clea-3: url(https://media-cf.wishi.me/react/campaigns/gifts/clea3.png);
    --gifts-clea-4: url(https://media-cf.wishi.me/react/campaigns/gifts/clea4.png);
    
    --arrow-left: url(https://media-cf.wishi.me/react/icons/arrowLeft.svg);
    --filters: url(https://media-cf.wishi.me/react/icons/filters.svg);
    --filters-hover: url(https://media-cf.wishi.me/react/icons/filters-hover.svg);
    --filters-white: url(https://media-cf.wishi.me/react/icons/filters-white.svg);
    --filters-red: url(https://media-cf.wishi.me/react/icons/filters-red.svg);
    --flag: url(https://media-cf.wishi.me/react/icons/flag.svg);
    --flag-fill: url(https://media-cf.wishi.me/react/icons/flagFill.svg);
    --new-messages: url(https://media-cf.wishi.me/react/icons/new-messages.svg);
    --new-messages-hover: url(https://media-cf.wishi.me/react/icons/new-messages-hover.svg);
    --new-messages-white: url(https://media-cf.wishi.me/react/icons/new-messages-white.svg);
    --new-messages-red: url(https://media-cf.wishi.me/react/icons/new-messages-red.svg);
    --view-more: url(https://media-cf.wishi.me/react/icons/more.svg);
    --view-more-hover: url(https://media-cf.wishi.me/react/icons/more-hover.svg);
    --view-more-white: url(https://media-cf.wishi.me/react/icons/more-white.svg);
    --view-more-red: url(https://media-cf.wishi.me/react/icons/more-red.svg);
    --search: url(https://media-cf.wishi.me/react/icons/search.svg);
    --back-to-top: url(https://media-cf.wishi.me/react/icons/back-to-top.svg);
    --archive: url(https://media-cf.wishi.me/react/icons/archiveNew.svg);
    --archive-hover: url(https://media-cf.wishi.me/react/icons/archive-hover.svg);

    --coral: #FA7268;
    --coral-light: #FEE3E1;
}