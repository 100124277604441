.inbox {
	&.container-fluid {
		min-height: calc(100vh - 70px);
		background-color: #f4f4f4;
		.container-fluid {
			height: 100%;
		}
	}
	.title {
		font-family: SabonLTStd-Italic;
		font-size: 28px;
		letter-spacing: 1.27px;
		height: 105px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #fff;
		border-bottom: 1px solid #f4f4f4;
	}

	.profile-image-placeholder {
		width: 50px;
		height: 50px;
		background-color: #000;
		border-radius: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: UntitledSans-Medium;
		letter-spacing: 1.1px;
		text-transform: uppercase;
		line-height: 20px;
		color: #fff;
		font-size: 20px;
	}

	@media screen and (max-width: 576px) {
		&.container-fluid {
			height: 100%;
			min-height: auto;
			background-color: #fff;
		}
	}
}

.bookings {
	background-color: #fff;
	.row {
		.col {
			p.title {
				height: 80px;
				font-family: UntitledSans-Medium;
				font-size: 16px;
				letter-spacing: 0.91px;
				justify-content: flex-start;
				padding: 25px;
				margin: 0;
				text-transform: capitalize;
			}

			&.list, &.chat, &.info {
				padding: 0;
				background-color: #fff;
				height: calc(100vh - 70px);
				overflow: hidden;
			}
			&.list {
				max-width: 375px;
				border-right: 1px solid #f4f4f4;
				margin: 0;
				.infinite-scroll-component__outerdiv {
					height: 100%;
					.infinite-scroll-component {
						height: auto;
						overflow-y: scroll;
					}
				}
				.bookings-list {
					height: calc(100% - 163px);
					overflow: scroll;
					&::-webkit-scrollbar {
						display: none;
					}
					-ms-overflow-style: none;
					scrollbar-width: none;
					.row {
						height: auto;
						border-bottom: 1px solid #f4f4f4;
					}
				}
			}
			&.chat {
				.chat-header {
					padding: 25px 30px 24px;
					display: flex;
					align-items: center;
					position: relative;
					height: 80px !important;
					font-family: UntitledSans-Medium;
					border-bottom: 1px solid #f4f4f4;

					.profile-photo {
						width: 48px;
						height: 48px;
						border-radius: 24px;
					}
					.client-name {
						display: flex;
						align-items: flex-start;
						flex-direction: column;
						.name {
							font-size: 16px;
							letter-spacing: 0.91px;
							margin: 0;
							padding-left: 15px;
							cursor: pointer;
							text-transform: capitalize;
							display: flex;

							.client-plan {
								font-family: UntitledSans-Regular;
								height: 25px;
								color: #fff;
								background-color: #000;
								font-size: 10px;
								letter-spacing: 0.85px;
								text-transform: uppercase;
								padding: 5px;
								margin-left: 8px;
							}
						}
						.social-icons {
							display: flex;
							position: relative;
							left: 15px;
							top: 0;
							a {
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: #000;
								width: 18px;
								height: 18px;
								border-radius: 10px;
								font-size: 12px;
								color: #f4f4f4;
								margin-right: 4px;
							}
						}
					}
					span {
						position: absolute;
						right: 30px;
						display: flex;
						align-items: center;
						.archive {
							cursor: pointer;
							&.disabled {
								pointer-events: none;
								opacity: .3;
							}
						}
						.unarchive {
							font-family: UntitledSans-Regular;
							font-size: 14px;
							letter-spacing: 0.45px;
							height: 37px;
							padding: 0 11px;
							text-transform: none;
						}
						.details-toggle {
							font-family: UntitledSans-Medium;
							font-size: 16px;
							letter-spacing: 0.91px;
							padding: 0;
							cursor: pointer;
							margin: 0 0 0 85px;
							&:hover {
								text-decoration: underline;
							}
						}
					}
					.unarchive-comment {
						font-family: UntitledSans-Regular;
						font-size: 16px;
						text-align: center;
						line-height: 25px;
						letter-spacing: 0.35px;
						position: absolute;
						top: 150px;
						left: -150px;
						width: 326px;
						img {
							position: absolute;
							top: -70px;
							left: 180px;
						}
					}
					.more-dropdown {
						&.show {
							.more-btn {
								background-color: #F46C66;
								border: none;
								.more-dots {
									background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='42'%3E%3Ccircle cx='6' cy='6' r='5' stroke='%23fff' stroke-width='2' fill='transparent' /%3E%3Ccircle cx='21' cy='6' r='5' stroke='%23fff' stroke-width='2' fill='transparent' /%3E%3Ccircle cx='36' cy='6' r='5' stroke='%23fff' stroke-width='2' fill='transparent' /%3E%3C/svg%3E");
								}
							}
						}
						.more-btn {
							padding: 0;
							width: 34px;
							height: 19px;
							background-color: #fff;
							display: flex;
							justify-content: center;
							border-radius: 0;
							align-items: center;
							border: none;
							box-shadow: none;
							margin-right: 16px;
							&:after {
								display: none;
							}
							.more-dots {
								background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='42'%3E%3Ccircle cx='6' cy='6' r='5' stroke='%23666666' stroke-width='2' fill='transparent' /%3E%3Ccircle cx='21' cy='6' r='5' stroke='%23666666' stroke-width='2' fill='transparent' /%3E%3Ccircle cx='36' cy='6' r='5' stroke='%23666666' stroke-width='2' fill='transparent' /%3E%3C/svg%3E");
								background-repeat: no-repeat;
								background-size: 24px 7px;
								background-position: center;
								width: 100%;
								height: 100%;
							}
						}
						.dropdown-menu {
							transform: translate3d(-210px, 30px, 0px) !important;
							overflow: auto;
							padding: 0;
							border-radius: 0;
							div {
								font-family: UntitledSans-Medium;
								font-size: 20px;
								color: #4a4a4a;
								padding: 0 13px;
							}
							a {
								font-family: UntitledSans-Regular;
								font-size: 16px;
								line-height: 40px;
								letter-spacing: 0.93px;
								text-transform: capitalize;
								color: #000;
								padding: 0 13px;
								&:active {
									background-color: #fff;
									color: #000;
								}
							}
						}
					}
				}
				.chat-container {
					height: calc(100vh - 130px);
					.client-wrapper {
						display: none;
					}
				}
			}
			&.info {
				max-width: 22%;
				border-left: 1px solid #f4f4f4;
				overflow: scroll;
				transition: max-width 200ms ease-in-out;

				.close {
					top: 32px;
					right: 21px;
					left: auto;
				}

				&.hide {
					max-width: 0;
				}
			}
		}
	}
}

.booking {
	&.row {
		&.selected {
			.col {
				background-color: #f4f4f4;
			}
		}
	}

	.booking-content {
		min-height: 145px;
		width: 100%;
		margin: 12px 22px;
		cursor: pointer;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: flex-start;
		padding: 10px;
		border-radius: 3px;
		z-index: 1;

		.important {
			background-image: url(https://media-cf.wishi.me/react/icons/booking-important-off.png);
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center;
			width: 18px;
			height: 19px;
			position: absolute;
			top: 5px;
			right: 5px;
			&.off { background-image: url(https://media-cf.wishi.me/react/icons/booking-important-off.png); }
			&.on { background-image: url(https://media-cf.wishi.me/react/icons/booking-important-on.png); }
		}

		svg {
			color: #f88379;
			font-size: 20px;
		}

		p {
			margin: 0;
			&.name {
				font-family: UntitledSans-Medium;
				font-size: 16px;
				letter-spacing: 0.73px;
				line-height: 1;
				text-transform: capitalize;
			}
			&.content {
				font-family: UntitledSans-Regular;
				font-size: 14px;
				letter-spacing: 0.64px;
				line-height: 21px;
				text-overflow: ellipsis;
				word-wrap: normal;
				overflow: hidden;
				margin: 7px 0;
			}
			&.date {
				font-family: UntitledSans-Regular;
				font-size: 12px;
				letter-spacing: 0.26px;
				color: #000;
				text-align: right;
				line-height: 1;
				position: absolute;
				bottom: 5px;
				right: 5px;
			}
			&.followup {
				font-family: UntitledSans-Regular;
				font-size: 14px;
				color: #f88379;
				letter-spacing: 0.44px;
				padding-bottom: 10px;
				display: flex;
				align-items: flex-start;
				width: 100%;
				button {
					font-family: UntitledSans-Medium;
					font-size: 12px;
					letter-spacing: 0.38px;
					color: #000;
					text-decoration: underline;
					cursor: pointer;
					position: absolute;
					right: 10px;
					top: 12px;
					z-index: 10;
					padding: 0;
				}
			}
		}
		.buttons {
			margin-bottom: 5px;
			button {
				font-family: UntitledSans-Regular;
				margin: 0 10px 10px 0;
				font-size: 14px;
				letter-spacing: 0.3px;
				padding: 10px;
				text-transform: none;
				line-height: 1;
				&:disabled {
					background-color: #7B7B81;
					color: #fff;
				}
			}
		}
	}
}

.bookings-filter {
	&.container {
		max-width: 329px;
		padding: 10px 0;
	}
	.row {
		margin: 0;
		.col {
			padding: 0;
			&.filter {
				max-width: 45px;
				margin-right: 12px;
				.dropdown {
					&.show {
						.dropdown-toggle:focus {
							box-shadow: none;
							background-color: #fff;
							border-color: #dbdbdb;
						}
					}
					.filter-dropdown {
						padding: 0;
						width: 45px;
						height: 45px;
						border: 1px solid #dbdbdb;
						border-radius: 0;
						background-color: #fff;
						display: flex;
						justify-content: center;
						align-items: center;

						.filter-icon {
							width: 22px;
							height: 15px;
							cursor: pointer;
						}

						&:hover, &:not(:disabled):not(.disabled):active {
							background-color: #fff;
							border-color: #dbdbdb;
						}
						&:after {
							display: none;
						}
					}
					.dropdown-menu {
						transform: translate3d(0px, 50px, 0px) !important;
						min-width: 329px;
						max-height: 458px;
						overflow: scroll;
						padding: 13px 0;
						border-radius: 0;
						div {
							font-family: UntitledSans-Medium;
							font-size: 20px;
							color: #4a4a4a;
							padding: 0 13px;
						}
						a {
							font-family: UntitledSans-Regular;
							font-size: 16px;
							line-height: 47px;
							letter-spacing: 0.34px;
							text-transform: capitalize;
							color: #000;
							padding: 0 13px;
							&:active {
								background-color: #fff;
								color: #000;
							}
						}
						.more {
							font-family: UntitledSans-Medium;
							font-size: 16px;
							letter-spacing: 0.73px;
							cursor: pointer;
							display: flex;
							align-items: center;
							&.off {
								i {
									transform: rotate(180deg);
								}
							}
							i {
								position: relative;
								width: 0;
								height: 0;
								border-left: 5px solid transparent;
								border-right: 5px solid transparent;
								border-bottom: 5px solid #000;
								left: 10px;
							}
						}
					}
				}
			}
			.search-box {
				&.search-on {
					.select__control {
						.select__value-container {
							padding-left: 8px;
						}
						.select__dropdown-indicator {
							display: none;
						}
						.select__indicators {
							left: auto;
							right: 0;
						}
					}
				}

				.select__control {
					min-width: 100%;
					height: 45px;
					border-radius: 0;
					position: relative;
					.select__placeholder {
						display: block;
						width: calc(100% - 45px);
					}
					.select__value-container {
						padding-left: 40px;
					}
					.select__clear-indicator {
						position: relative;
					}
					.select__indicators {
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 40px;
						cursor: pointer;
						.select__dropdown-indicator {
							border-color: #6C6C6C;
							right: auto;
							left: 15px;
							&:before {
								background-color: #6C6C6C;
							}
						}
					}
				}
			}
		}
	}
}

.messages {
	background-color: #f4f4f4;
	padding-bottom: 26px;
	.container {
		max-width: 92%;
	}

	@media screen and (max-width: 576px) {
		padding-bottom: 0;
		background-color: #fff;
		.container {
			max-width: 100%;
		}
	}
}

.message {
	height: 134px;
	background-color: #fff;
	margin-top: 26px;
	padding: 0 18px;
	cursor: pointer;

	.col {
		display: flex;
		justify-content: space-between;
	}

	.stylist-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		max-width: 120px;
		margin: 0;

		img {
			width: 80px;
			height: 80px;
			border-radius: 40px;
		}
		p {
			font-family: UntitledSans-Regular;
			font-size: 12px;
			letter-spacing: 0.25px;
			color: #fff;
			text-transform: uppercase;
			background-color: #f88379;
			text-align: center;
			height: 19px;
			line-height: 1.6;
			padding: 0 12px;
			&.cleanout {
				background-color: #74ae37;
			}
		}
	}
	.text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		svg {
			color: #f88379;
			font-size: 20px;
		}
		p {
			margin: 0;
			&.name {
				font-family: UntitledSans-Medium;
				font-size: 18px;
				letter-spacing: 0.82px;
			}
			&.content {
				font-family: UntitledSans-Light;
				font-size: 16px;
				letter-spacing: 0.73px;
				text-overflow: ellipsis;
				word-wrap: normal;
				overflow: hidden;
				max-height: 3.6em;
				line-height: 1.7em;
				padding-right: 65px;
				max-width: 856px;
			}
			&.date {
				font-family: UntitledSans-Light;
				font-size: 12px;
				letter-spacing: 0.55px;
				color: #4a4a4a;
			}
		}
	}
	.buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		button {
			min-width: 175px;
			padding: 15px;
			margin-left: 10px;
			white-space: nowrap;
			&.btn-light {
				&:hover {
					background-color: #000;
					color: #fff;
				}
			}
		}
	}

	@media screen and (max-width: 576px) {
		margin: 1px;
		height: 150px;
		border-bottom: 1px solid #f4f4f4;
		padding: 20px 10px;
		.col {
			flex-direction: column;
			justify-content: space-around;
			padding: 0;
		}
		.stylist {
			max-width: 67px;
			margin-right: 10px;
			img {
				width: 67px;
				height: 67px;
			}
		}
		.text {
			width: auto;
			p {
				&.content {
					font-size: 14px;
					padding: 0;
					height: 50px;
					max-width: 523px;
					width: 72%;
				}
				&.date {
					position: absolute;
					top: 5px;
					right: 10px;
					&:after {
						position: relative;
						top: -2px;
						left: 2px;
						content: '';
						display: inline-block;
						width: 5px;
						height: 5px;
						border-right: 1px solid black;
						border-top: 1px solid black;
						transform: rotate(45deg);
					}
				}
			}
		}
		.buttons {
			max-width: 100%;
			display: block;
			margin-top: 5px;
			button {
				min-width: 0;
				max-height: 25px;
				font-size: 12px;
				line-height: .1;
				border: none;
				background-color: #f88379;
				color: #fff;
				padding: 15px 20px;
				margin-left: 0;
				margin-right: 10px;
			}
		}
	}
}

.client-info {
	.nav-tabs {
		border: none;
		justify-content: space-evenly;
		.nav-item {
			font-family: UntitledSans-Regular;
			font-size: 12px;
			letter-spacing: 0.26px;
			color: #000;
			border: none;
			padding: 0;
			margin: 17px 0;
			&.active {
				font-family: UntitledSans-Medium;
				color: #000;
				border-bottom: 2px solid;
			}
		}
	}
	.profile-image-placeholder {
		margin: 0 auto;
	}

	.tab-content {
		width: 100%;
		margin: 0 auto;

		.tab-pane {
			.photos {
				.photos-carousel {
					margin-bottom: 18px;
					.slick-slide {
						img {
							height: 375px;
							object-fit: contain;
							cursor: pointer;
						}
					}
					.slick-next,
					.slick-prev {
						width: 34px;
						height: 34px;
						border-radius: 17px;
						background-color: #fff;
						border: 1px solid #979797;
						z-index: 99;
						&:before {
							position: relative;
							content: '';
							display: inline-block;
							width: 10px;
							height: 10px;
							border-right: 2px solid black;
							border-top: 2px solid black;

						}
					}
					.slick-prev {
						left: -10px;
						&:before {
							left: 2px;
							transform: rotate(225deg);
						}
					}
					.slick-next {
						right: -10px;
						&:before {
							right: 2px;
							transform: rotate(45deg);
						}
					}
				}
				.list-group {
					.list-group-item {
						border: none;
						padding: 0;
						text-transform: capitalize;
						font-family: UntitledSans-Regular;
						font-size: 12px;
						letter-spacing: 0.26px;
						margin-bottom: 15px;
						.blank-image {
							min-width: 24px;
							min-height: 24px;
							border: 2px solid #f4f4f4;
							margin-right: 16px;
							display: inline-block;
						}
						.col-1 {
							img {
								min-width: 15px;
								max-width: 15px;
								max-height: 15px;
								margin-right: 16px;
								display: inline-block;
							}
						}

						b {
							font-family: UntitledSans-Medium;
							font-weight: normal;
						}
						.row {
							align-items: center;
							.col {
								img {
									width: 36px;
									height: 13px;
									margin-right: 4px;
								}
							}
						}
					}
				}
			}
			.style {
				font-family: UntitledSans-Regular;
				.col-12 {
					p {
						font-size: 16px;
						letter-spacing: 0.5px;
						text-align: center;
						margin: 0;
						padding-top: 30px;
					}
				}
				.moodboard {
					text-align: center;
					p {
						font-size: 14px;
						color: #4a4a4a;
						margin: 10px auto 6px;
						text-align: center;
						text-transform: capitalize;
					}
					img {
						max-width: 70px;
					}
				}
			}
			.boards {
				.toggle-button {
					height: 30px;
					width: 100%;
					button {
						padding: 8px 0;
						letter-spacing: 0.56px;
						line-height: 1;
						text-transform: capitalize;
						border-color: #000;
						border-radius: 0;
					}
				}
				.col {
					text-align: center;
					img {
						max-width: 275px;
						border: 1px solid #f4f4f4;
						margin-bottom: 13px;
					}
				}
			}
			.notes {
				.note {
					border-bottom: 1px solid #f4f4f4;
					padding: 20px 0;
					font-family: UntitledSans-Regular;
					.note-header {
						height: auto;
						padding-bottom: 20px;
						p {
							font-size: 12px;
							line-height: 23px;
							letter-spacing: 0.64px;
							margin: 0;
							&.name {
								font-family: UntitledSans-Medium;
							}
							&.time {
								color: #9b9b9b;
							}
						}
						.profile-photo {
							width: 36px;
							height: 36px;
						}
					}
					.note-content {
						font-size: 16px;
						line-height: 19px;
						height: auto;
					}
				}
			}
		}
	}
}