.collage {
	&.container-fluid {
		height: 100vh;
		display: flex;
		padding: 0;
		flex-direction: column;
	}

	&.with-message .row.images-row img {
		height: calc(33vh - 37px);
	}

	.row {
		flex-grow: 1;

		&.images-row {
			img {
				padding: 3px 0 3px 6px;
				object-fit: cover;
				object-position: 50% 0;
				width: 33%;
				height: calc(33vh - 17px);
				transition: height 200ms ease-in-out;
			}
		}
	}
}