.DeactivateAccount {
	.deactivate-account {
		width: 100%;
		max-width: 300px;
		margin: 0 auto;
		text-align: center;
	
		.modal-header {
			p {
				font-family: UntitledSans-Regular;
				font-size: 18px;
				line-height: 20px;
				letter-spacing: 0.58px;
				margin: 0 auto;
			}
			.close {
				width: 10px;
				height: 10px;
				right: 5px;
				left: auto;
				top: 10px;
				&:before, &:after {
					height: 10px;
					left: 0;
					top: 0;
				}
			}
		}
	
		.modal-body {
			p {
				font-family: UntitledSans-Regular;
				font-size: 14px;
				letter-spacing: 0.7px;
				max-width: 234px;
				margin: 10px auto;
			}
		}
	
		.modal-footer {
			button {
				font-family: UntitledSans-Regular;
				width: 174px;
				height: 33px;
				font-size: 14px;
				letter-spacing: 1px;
				border-radius: 0;
				text-transform: none;
	
				&:focus, &:hover {
					border: none;
				}
			}
			.deactivate-error {
				font-size: 14px;
				color: #e41304;
			}
		}
	}
}
