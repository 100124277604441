.reset-confirmation {
	width: 100%;

	.modal-body {
		p {
			margin-top: 18px;
			font-size: 18px;
			font-weight: 300;
			line-height: 1.5;
			letter-spacing: 1.1px;
			text-align: center;

			a {
				color: #000;
				font-weight: 600;
			}
		}
	}

	@media screen and (max-width: 768px) {
		.modal-body {
			p {
				white-space: pre-line;
			}
		}
	}
}