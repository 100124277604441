.styling-room-boards,
.styling-room-closet,
.styling-room-store,
.styling-room-shop,
.styling-room-inspiration {

	display: flex;

	.filters-column {
		max-width: 280px;
		border-right: 1px solid #f4f4f4;
		&::-webkit-scrollbar {
			display: none;
		  }
		.total {
			padding-top: 25px;
			font-family: UntitledSans-Medium, sans-serif;
			font-size: 14px;
			letter-spacing: 0.82px;
		}
		.filters {
			&.categories-all {
				.filter-row.category_uuid {
					.filter .selection {
						&:after {
							content: 'All categories';
						}
					}
				}
			}
			.filters-list .filter-row {
				border-bottom: 0 !important;
				padding: 0;
				.filter {
					font-size: 14px;
					font-family: UntitledSans-Regular, sans-serif;
					line-height: 41px;
					letter-spacing: 0.82px;
					.expand-sign {
						&:after {
							content: '';
							width: 0;
							height: 0;
							top: 20px;
							border-left: 5px solid transparent;
							border-right: 5px solid transparent;
							border-top: 5px solid #2f2f2f;
							transition: transform 300ms ease-in-out;
						}
					}
				}
				&.active {
					.filter {
						.expand-sign {
							&:after {
								content: '';
								transform: rotate(180deg);
							}
						}
					}
				}
				.filter-item {
					&.selected {
						.filter-label {
							font-family: UntitledSans-Medium, sans-serif;
						}
					}
					.filter-label {
						font-size: 14px;
						&:before {
							position: relative;
							margin-right: 8px;
							min-width: 11px;
							max-width: 11px;
							max-height: 11px;
							border: 1px solid #000;
						}
						&:not(.single) {
							&:before {
								border-radius: 0;
							}
						}
					}
				}
			}
			.show-all {
				font-size: 14px;
			}
		}
	}
	.results {
		padding: 12px 40px 25px 40px;

		.results-bar {
			display: flex;
			align-items: center;
			padding: 9px 5px 12px;
			position: relative;
			min-height: 50px;
			.dropdown {
				.dropdown-toggle, .dropdown-toggle:focus{
					font-family: UntitledSans-Regular, sans-serif;
					font-size: 14px;
					border: 1px solid #1D1D1D;
					height: 32px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 13px;
					border-radius: 2px;
					&:after {
						top: 0;
						border-right: 1px solid #1D1D1D;
						border-top: 1px solid #1D1D1D;
						width: 6px;
						height: 6px;
					}
				}
				.dropdown-item {
					font-size: 14px;
					padding: 5px 15px;
				}
			}

			.favs-btn {
				position: relative;
				cursor: pointer;
				width: 32px;
				height: 32px;
				border: 1px solid #1D1D1D;
				border-radius: 2px;
				margin-right: 13px;
				background-repeat: no-repeat;
				background-size: 80%;
				background-position: center center;
				transition: opacity 100ms ease-in-out;
				&:after {
					content: '';
					display: block;
					position: absolute;
					width: 20px;
					height: 20px;
					top: 4px;
					right: 5px;
					z-index: 10;
					background-size: 100%;
					background-repeat: no-repeat;
					background-image: url("data:image/svg+xml;utf8,<svg width='48' height='48' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' viewBox='0 0 24 24'><path d='M15.668 8.626l8.332 1.159-6.065 5.874 1.48 8.341-7.416-3.997-7.416 3.997 1.481-8.341-6.064-5.874 8.331-1.159 3.668-7.626 3.669 7.626zm-6.67.925l-6.818.948 4.963 4.807-1.212 6.825 6.068-3.271 6.069 3.271-1.212-6.826 4.964-4.806-6.819-.948-3.002-6.241-3.001 6.241z'/></svg>");
				}
				&.on {
					&:after {
						background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24' fill='rgb(248, 131, 121)'><path d='M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z'/></svg>");
					}
				}
			}
			.search-box {
				padding-right: 13px;
				display: inline-flex;
				&.expanded {
					.select__control {
						width: 150px;
						.select__input {
							color: #000;
						}
					}
				}
				.select__indicators {
					display: block;
				}
				.select__control {
					width: 32px;
					min-height: 32px;
					height: 32px;
					border-color: #1D1D1D;
					border-radius: 2px;
					overflow: hidden;
					&.select__control--is-focused {
						.select__value-container {
							padding-left: 8px;
						}
						.select__indicators {
							display: none;
						}
					}
					.select__input {
						color: transparent;
					}
					.select__value-container {
						padding-left: 25px;
					}
					.select__indicators .select__dropdown-indicator {
						left: 7px;
						top: 8px;
						position: absolute;
					}
				}
			}
		}
	}
}

.styling-room-shop {
	.results {
		.results-bar {
			.dropdown {
				display: flex;
				position: absolute;
				right: 80px;
			}
		}
	}
}

.styling-room-inspiration {
	display: flex;
	justify-content: center;

	.results {
		max-width: 1140px;
		margin: 0 auto;
		padding: 0;
		.results-bar {
			height: 75px;
			display: flex;
			align-items: center;
			max-width: 1140px;
			margin: auto;
			p {
				font-family: UntitledSans-Medium, sans-serif;
				font-size: 14px;
				padding-right: 13px;
				margin: 0;
				display: inline-flex;
			}
		}
		.col-3 {
			padding: 20px 10px;
			overflow: hidden;
			object-fit: contain;
			max-height: 410px;
			img {
				height: auto;
				cursor: pointer;
				max-width: 100%;
			}
			&:hover {
				.fav-star {
					opacity: 1;
				}
			}
		}
		.fav-star, .remove {
			position: absolute;
			top: 25px;
			right: 17px;
			z-index: 10;
			background-color: #fff;
			cursor: pointer;
		}
		.remove {
			width: 20px;
			height: 20px;
			&:before, &:after {
				position: absolute;
				content: '';
				height: 100%;
				width: 1px;
				background-color: #000;
			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
		}
		.fav-star {
			width: 20px;
			height: 20px;
			background-size: 80%;
			background-position: center;
			opacity: 0;
			background-repeat: no-repeat;

			&.empty {
				background-image: url("data:image/svg+xml;utf8,<svg width='48' height='48' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' viewBox='0 0 24 24'><path d='M15.668 8.626l8.332 1.159-6.065 5.874 1.48 8.341-7.416-3.997-7.416 3.997 1.481-8.341-6.064-5.874 8.331-1.159 3.668-7.626 3.669 7.626zm-6.67.925l-6.818.948 4.963 4.807-1.212 6.825 6.068-3.271 6.069 3.271-1.212-6.826 4.964-4.806-6.819-.948-3.002-6.241-3.001 6.241z'/></svg>");

			}
			&.filled {
				opacity: 1;
				background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24' fill='rgb(248, 131, 121)'><path d='M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z'/></svg>");
			}
		}
	}
}

.styling-room-boards {
	.results {
		padding-top: 12px;
	}
	.board {
		width: 275px;
		height: 275px;
		border: 1px solid #dbdbdb;
		margin: 0 20px 20px 0;
		position: relative;
		display: inline-block;
		cursor: pointer;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	
	.loading{
		border: none;
	}
	
}